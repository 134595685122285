import React , { useState, useEffect } from 'react'
import log from 'Logging'
import $ from 'jquery'

export default function LazyImage ({ id, src, alt, placeholder, onClick, onLoaded=()=>{}, forceLoad=false }) {

    const [imageSrc, setImageSrc] = useState(placeholder == 'loader' || placeholder == 'hide' ? null : placeholder);
    const [imageRef, setImageRef] = useState();
    const [loaded, setLoaded ] = useState(false);

    const onLoad = event => {
        setLoaded(true);
        event.target.classList.add('loaded') // fade anim, not used for performance
        onLoaded();
    };

    const onError = event => {
        setLoaded(true);
        event.target.classList.add('has-error')
    };

    useEffect( () => {

        //log.Rendering(`Image ${id} rendered`, "cyan", `imageSrc: ${imageSrc}`, `imageRef: ${imageRef}`, `loaded: ${loaded}`);

        let observer
        let didCancel = false

        if(forceLoad){ // immediately load if set
            setImageSrc(src)
            return;
        }
        else{

            if (imageRef && imageSrc !== src) {
                if (IntersectionObserver) {
                    observer = new IntersectionObserver(
                        entries => {
                            entries.forEach(entry => {
                                if (
                                    !didCancel &&
                                    (entry.intersectionRatio > 0 || entry.isIntersecting)
                                ) {
                                    setImageSrc(src) // init loading
                                    observer.unobserve(imageRef)
                                }
                            })
                        },
                        {
                            threshold: 0.01,
                            rootMargin: '75%',
                        }
                    )
                    observer.observe(imageRef)
                } 
                else {
                    // Old browsers fallback
                    setImageSrc(src)
                }
            }
    
        }
        
        return () => {
            didCancel = true
            if(!forceLoad){
                // on component cleanup, we remove the listner
                if (observer && observer.unobserve) {
                    observer.unobserve(imageRef)
                }
            }
        };

    }, [src, imageSrc, imageRef]);

    return (
            <div className="img-container uk-inline">
                <img
                    id={id}
                    ref={setImageRef}
                    src={imageSrc}
                    alt={alt}
                    onLoad={onLoad}
                    onError={onError}
                    onClick={onClick}
                    style={{
                        visibility: loaded ? "" : ( placeholder != 'loader' || placeholder != 'hide' ? '' : "hidden")
                    }}
                />
                { placeholder == 'loader' && !loaded && 
                    <div className="loader uk-overlay uk-position-top" />
                }
            </div>
    );
}