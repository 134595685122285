export const IMAGE_SELECT = "selectImage";
export const IMAGES_SELECT_ALL = "selectAllImages";

export const CATEGORY_ACTIVATE = "activateCategory";
export const WORK_ACTIVATE = "activateWork";
export const IMAGE_ACTIVATE = "activateImage";

export const CATEGORIES_SET = "setCategories";
export const CATEGORY_WORKS_SET = "setCategoryWorks";
export const WORK_IMAGES_SET = "setWorkImages";

export const WORK_RENAME = "renameWork";
export const WORK_REDESCRIPT = "redescriptWork";
export const CATEGORY_RENAME = "renameCategory";

export const WORK_DELETE = "deleteWork";
export const CATEGORY_DELETE = "deleteCategory";
export const IMAGE_DELETE = "deleteImage";
export const IMAGES_SELECTED_DELETE = "deleteSelectedImages";

export const GALLERY_DISPLAY_ONLY_SET = 'setGalleryDisplayOnly';