import React, { useEffect } from 'react'
import log from 'Logging'
import { motion } from 'framer-motion';

// hooks
import {useLog, usePageTitle, useRedux} from 'hooks/components'

import Header from './Header'
import CategoriesPane from './CategoriesPane'
import WorksPane from './WorksPane';
import Viewer from './Viewer';
import LegalFooter from 'components/pages/LegalFooter';

import MediaQuery from 'react-responsive'

import './gallery_manager.css'
import actions from 'state/gallery_manager/actions';

const galleryContainerVariants = {
    initial:{
        x: "100vw",
        transition: { ease: 'easeInOut'}
    },
    enter: {
        x: "0vw",
        transition: { ease: 'easeInOut'}
    },
    exit: {
        x: "100vw",
        transition: { ease: 'easeInOut'}
    }
}

const GalleryManager = ({headerTitle='Galleria [Manager]', displayOnly=false}) => {
    
    const logConfig = {
        name: `GalleryManager`,
        color: "brown"
    }
    useLog(logConfig);

    usePageTitle(`Geotek - ${headerTitle}`);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => (false) // never rerender for redux state changes
    }, logConfig);

    useEffect(()=>{
        dispatch(actions.set.galleryDisplayOnly(displayOnly));
    },[displayOnly])

    useEffect(() => { 
        dispatch(actions.set.categories())  // fetch categories on page load
    }, []);

    return (
        <motion.div className=''
            variants={galleryContainerVariants}
            initial='initial'
            animate='enter'
            exit='exit'
        >
            <div id='gallery_vista' className={`${displayOnly ? "gallery_display_only" : ""}`}>
                <Header title={headerTitle}/>
                <div className="content">
                    <MediaQuery minWidth={950}>
                        <CategoriesPane/>
                    </MediaQuery>
                    <WorksPane />
                </div>
                <Viewer />
                
                <LegalFooter />

            </div>   
        </motion.div>    
    )
};

export default GalleryManager;