
import React, { useEffect, useState, useMemo } from 'react';
import * as yup from 'yup';
import MyForm, { Field, Check, IconButton, Select } from 'components/MyForm';
import Form from 'react-bootstrap/Form'

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/gallery_manager/actions'

// hooks
import { useRedux, useLog, useModal } from 'hooks/components';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'hooks/storage';
import { useBackend } from 'hooks/ajax';
import routes, { backend } from 'routes';

import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ImageDropZoneField from 'components/ImageDropZone';

// ------ ADD ---------

/* popover */

export const AddWorkForm = ({ category, redux, close, reset }) => {

    const [gallery, dispatch] = redux;

    const logConfig = {
        name: 'AddWorkForm'
    };

    useLog(logConfig)

    const [addWorkRequest, addWorkResponse] = useBackend({
        route: backend.reserved.manage.gallery.add.work,
        method: 'post'
    })

    const schema = yup.object().shape({
        name: yup.string().required(),
        workCategoryId: yup.string()
    });

    return (
        <MyForm
            schema={schema}
            request={addWorkRequest}
            initialValues={{
                name: "",
                workCategoryId: "",
            }}
            submitButton={{ title: `Aggiungi Lavoro a ${category.name}` }}
            onComplete={{
                messages: {
                    success: 'Work added succesfully',
                    includeErrorData: true
                }
            }}
            callbacks={{
                onSuccess: (data) => { dispatch(actions.activate.category(category)); close(); }
            }}
            reset={reset}
        >

            <Field
                id='0'
                type="text"
                placeholder="Nome"
                name="name"
            />

            <Field
                id='1'
                type="hidden"
                injectedFormData={{inputName: 'workCategoryId', getFormDataObj: ()=>(category.id) }}
            />

        </MyForm>

    )
}

export const AddCategoryForm = ({ redux, close, reset }) => {

    const [galleryManager, dispatch] = redux;

    const logConfig = {
        name: 'AddWorkForm'
    };

    useLog(logConfig)

    const [addWorkRequest, addWorkResponse] = useBackend({
        route: backend.reserved.manage.gallery.add.workCategory,
        method: 'post'
    })

    const schema = yup.object().shape({
        name: yup.string().required(),
    });

    return (
        <MyForm
            schema={schema}
            request={addWorkRequest}
            initialValues={{
                name: "",
            }}
            submitButton={{ title: `Aggiungi Raccolta` }}
            onComplete={{
                messages: {
                    success: 'Category added succesfully',
                    includeErrorData: true
                }
            }}
            callbacks={{
                onSuccess: (data) => { dispatch(actions.set.categories()); close(); }
            }}
            reset={reset}
        >

            <Field
                id='0'
                type="text"
                placeholder="Nome"
                name="name"
            />

        </MyForm>

    )
}

export const MoveWorkToCategoryForm = ({ work, category, redux, close, reset }) => {

    const [galleryManager, dispatch] = redux;

    const [addWorkRequest, addWorkResponse] = useBackend({
        route: `${backend.reserved.manage.gallery.edit.work}/${work.id}/category`,
        method: 'post'
    })

    const schema = yup.object().shape({
        newCategory: yup.string().required(),
    });

    const options = galleryManager.categories.filter(cat=> cat.id != category.id);

    return (
        <MyForm
            schema={schema}
            request={addWorkRequest}
            submitButton={{ title: `Sposta` }}
            initialValues={{
                newCategory: options[0].id,
            }}
            onComplete={{
                messages: {
                    success: 'Work moved succesfully',
                    includeErrorData: true
                }
            }}
            callbacks={{
                onSuccess: (data) => { dispatch(actions.delete.work(work)); close(); }
            }}
            reset={reset}
        >

            <Select label="Sposta in:" name="newCategory">
                <option value={0}>Nascosti</option>
                { galleryManager.categories.filter(cat=> cat.id != category.id).map( cat => <option value={cat.id}>{cat.name}</option>) }
            </Select>

        </MyForm>

    )
}

/* dropzone */

export const AddImagesForm = ({work, redux}) => {

    const logConfig = {
        name: 'AddImagesForm'
    };

    useLog(logConfig)

    const [galleryManager, dispatch] = redux;

    const [progress, setProgress] = useState(0);

    const [uploadRequest, uploadResponse] = useBackend({
        route: `${backend.reserved.manage.gallery.add.images}/${work.id}`,
        method: 'post'
    },  
    {
        headers: {
            'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => setProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total ))
    })

    const schema = yup.object().shape({
        files: yup.array().required().test({test: arr => arr.length > 0, message: "Seleziona delle immagini"})
    });

    return (
        <MyForm
            schema={schema}
            request={uploadRequest}
            initialValues={{
                files: []
            }}
            submitButton={{ title: 'Upload' }}
            onComplete={{
                messages: {
                    success: 'Upload Succesful',
                    includeErrorData: true
                }
            }}
            callbacks={{
                onSuccess: (data) => {
                    if(galleryManager.active.work.id == work.id){ // if work was opened then reload it
                        dispatch(actions.activate.work(work))
                    }
                },
                onError: (code, data) => {
                    setProgress(0);
                }
            }}
            enctype="multipart/form-data"
        >

            <ImageDropZoneField inputName='files' id='1' />

            { progress > 0 && progress < 100 &&
                <Form.Group>
                    <progress className="uk-progress" value={progress} max="100"></progress> 
                    <Form.Text className="text-muted">
                        Uploading: {progress}%
                    </Form.Text>
                </Form.Group>            
            }
            {   progress >= 100 &&
                <Form.Group>
                    <Form.Text className="text-muted">
                        Elaborazione in corso ...
                    </Form.Text>
                </Form.Group>
            }

        </MyForm>

    )
}

// ------ DELETE --------

/* popover */

export const DeleteCategoryForm = ({ category, redux }) => {

    const [galleryManager, dispatch] = redux;

    const [deleteCategoryRequest, deleteCategoryResponse] = useBackend({
        route: `${backend.reserved.manage.gallery.delete.workCategory}/${category.id}`,
        method: 'post'
    })

    const schema = yup.object().shape({
        cascade: yup.string(),
    });

    return (
        <MyForm
            schema={schema}
            request={deleteCategoryRequest}
            initialValues={{
                cascade: "",
            }}
            submitButton={{ title: 'Elimina', component: IconButton, ukIcon: 'trash', ukClass: 'button-iconed uk-button-danger' }}
            onComplete={{
                messages: {
                    includeErrorData: true
                }
            }}
            callbacks={{
                onSuccess: (data, values) => {
                    dispatch(actions.delete.category(category))
                }
            }}
        >

            <Check
                id={category.id}
                type='checkbox'
                name="cascade"
                label={`Elimina tutti i lavori`}
                uk-tooltip='title: Se non viene spuntata, i lavori di questa raccolta andranno dentro "Nascosti"; pos: top'
            />

        </MyForm>

    )
}
