// core
import { useRef, useEffect, useReducer } from 'react'
import log from 'Logging'
import update from 'immutability-helper';
import _ from 'lodash';

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/auth/actions'


const defaultLogConfig = {
    name: '',
    color: 'white'
}

/**
 * @export
 * @param {*} logConfig = {
 *              name: 'Comp'
 *              color: 'white'
 *          }
 */
export function useLog(_logConfig) {

    const logConfig = _.mergeWith({}, defaultLogConfig, _logConfig)

    useEffect(() => {
        log.Rendering(`${logConfig.name} rendered`, logConfig.color);
    })

    useEffect(() => {
        log.Loaded(`${logConfig.name} loaded`, logConfig.color);
    }, []);

    useEffect(() => {
        return () => {
            log.Unmounting(`${logConfig.name} unmounted`, logConfig.color)
        }
    })
}

export function usePageTitle(title){
    useEffect(()=>{ document.title = title; },[]);
}

/**
 * @param {*} reduxConfig = {
 *              selector: auth
 *              reRender: (a,b) => true
 *          }
 */
export function useRedux(reduxConfig, logConfig = null) {

    const selector = useSelector(state => state[reduxConfig.selector], (a, b) => {
        const reRender = reduxConfig.reRender(a, b);
        log.Equating(`${logConfig.name} equating...`, logConfig.color, "ReRender: " + reRender);
        return !reRender;
    });
    const dispatch = useDispatch();

    return [selector, dispatch]
}

export function useModal() {

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_SHOW': {
                return update(state, {
                    show: { $set: action.show }
                });
            }
            case 'SET_CONTENT': {
                return update(state, {
                    content: { $set: action.content }
                });
            }
            case 'SET_TITLE': {
                return update(state, {
                    title: { $set: action.title }
                });
            }
            case 'SET_KEY': {
                return update(state, {
                    key: { $set: action.key }
                });
            }
            default: {
                return state;
            }
        }
    }
    const defaultModal = {
        show: false,
        title: '',
        content: '',
        key: ''
    }
    const [modal, dispatch] = useReducer(reducer, defaultModal)

    const handleModal = {
        close: () => dispatch({ type: 'SET_SHOW', show: false }),
        show: () => dispatch({ type: 'SET_SHOW', show: true }),
        content: (content) => dispatch({ type: 'SET_CONTENT', content: content }),
        title: (title) => dispatch({ type: 'SET_TITLE', title: title }),
        key: (key) => dispatch({ type: 'SET_KEY', key: key })
    }

    return [modal, handleModal];
}

export function useAlert() {

    const defaultAlert = {
        variant: 'primary',
        msg: null
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_VARIANT': {
                return update(state, {
                    variant: { $set: action.variant }
                });
            }
            case 'SET_MSG': {
                return update(state, {
                    msg: { $set: action.msg }
                });
            }
            case 'RESET':
                {
                    return defaultAlert;
                }
            default: {
                return state;
            }
        }
    }

    const [alert, dispatch] = useReducer(reducer, defaultAlert)

    const setAlert = (alert) => {
        if (alert == null) {
            dispatch({ type: 'RESET' });
        }
        else {
            dispatch({ type: 'SET_MSG', msg: alert.msg });
            dispatch({ type: 'SET_VARIANT', variant: alert.variant })
        }
    }

    return [alert, setAlert];
}
