import Alert from 'react-bootstrap/Alert'
import { useToast } from "@chakra-ui/react"

export default function ConditionalAlert({useAlert: alert}){
    
    const toast = useToast();
    const toastId = "acpcbt";

    return (   
        <>    
            { alert.msg != null &&
                <Alert variant={alert.variant}>
                    <div onClick={() => {
                        if (!toast.isActive(toastId) && alert.variant == 'danger') {
                            navigator.clipboard.writeText(JSON.stringify(alert.msg).replace(/\\/g, ''))
                            toast({
                                id: toastId,
                                title: "Copied to clipboard",
                                status: "info",
                                position: "top",
                                duration: 1000,
                                isClosable: false,
                            })
                        }
                    }} > {alert.msg} </div>
                </Alert>
            }
        </>
    )
}