import * as types from './actionsTypes';
import log from 'Logging';
import axios from 'axios';

import {backend} from 'routes'
import { ajax } from 'hooks/ajax';

const actions = {

    delete:{
        work: (work) => ({
            type: types.WORK_DELETE,
            payload: {
                work: work
            }           
        }),
        category: (category) => {
            return (dispatch, getState) => {
                    
                    if(category.id == getState().galleryManager.active.category.id){ // active category was eliminated, activate uncategorized
                        dispatch(actions.activate.category(null))
                    }
                    
                    dispatch({
                        type: types.CATEGORY_DELETE,
                        payload:{
                            category: category
                        }
                    });

            }
        },
        image: (image) => ({
            type: types.IMAGE_DELETE,
            payload: {
                image: image
            }           
        }),
        selectedImages: () => ({
            type: types.IMAGES_SELECTED_DELETE,
        })
    },

    /* schema: 
            edit -> 
                objName -> 
                    propertyName (obj, newValue) => ({ 
                        type: OBJ_EDITPROP1,
                        payload:{
                            obj: obj,
                            newValue: newValue
                        }
                    })
    */
    edit:{
        work:{
            name: (work, newName) => ({
                type: types.WORK_RENAME,
                payload: {
                    work: work,
                    newName: newName,
                }
            }),
            description: (work, newDescription) => ({
                type: types.WORK_REDESCRIPT,
                payload: {
                    work: work,
                    newDescription: newDescription,
                }
            }),
        },
        category: {
            name: (category, newName) => ({
                type: types.CATEGORY_RENAME,
                payload:{
                    newName: newName,
                    category: category
                }
            })
        }
    },

    select:{
        image: (image, isSelected) => ({
            type: types.IMAGE_SELECT,
            payload: {
                image: image,
                isSelected: isSelected
            }
        }),
        
        allImages: (isSelected) => ({
            type: types.IMAGES_SELECT_ALL,
            payload: {
                isSelected: isSelected
            }
        }),
    },

    set:{

        galleryDisplayOnly: (displayOnly) => ({
            type: types.GALLERY_DISPLAY_ONLY_SET,
            payload:{
                displayOnly: displayOnly
            }
        }),

        workImages: (images) => ({
            type: types.WORK_IMAGES_SET,
            payload:{
                images: images
            }
        }),

        categoryWorks: (works) => ({
            type: types.CATEGORY_WORKS_SET,
            payload:{
                works: works
            }
        }),
    
        categories: () => {
            return (dispatch) => {

                return ajax({
                    route: backend.gallery.display.workCategories,
                    method: 'get',
                    logTitle:{
                        fetch: `Categories: `
                    },
                    callbacks:{
                        onSuccess: (data) => {
                            let categories = data;
                            dispatch({
                                type: types.CATEGORIES_SET,
                                payload:{
                                    categories: categories
                                }
                            });
                            
                            dispatch(actions.activate.category(categories[0])); // autoselect first category
                        }
                    }
                });
            }
        },
    },

    activate:{

        work: (work, ref=null) => {
            return (dispatch, getState) => {
                dispatch({
                    type: types.WORK_ACTIVATE,
                    payload:{
                        work: work
                    }
                });
    
                if(work == null){
                    return null;
                }
                    
    
                return ajax({
                    route: `${backend.gallery.display.works}/${work.id}/images`,
                    method: 'get',
                    logTitle:{
                        fetch: `Work ${work.id} data: `
                    },
                    callbacks:{
                        onSuccess: (data) => {
                            
                            let images = data;
                            
                            if(work.id == getState().galleryManager.active.work.id){ // prevent setting images if other work is activated during request
                                dispatch(actions.set.workImages(images));
                                if(ref && ref.current){
                                    ref.current.scrollIntoView({behavior: "smooth"});
                                }
                            }
                            
                        }
                    },
                    manipulate: (data) => {
                        let images = data;
                        // fixes raw path and make it a download_url property
                        images.forEach( (image, index) => {
                            image['download_url'] = `${axios.defaults.baseURL}${image.path.replace(/^\/public/, "")}`; // slash is not inserted because it's already provided in image.path realative to public symfony folder
                            image['thumb_url'] = `${axios.defaults.baseURL}${image.thumbPath}`;
                        })

                        log.Fetch(`Work ${work.id} images: `, null, images);

                        return images;
                    }
                });
            }   
        },
        
        category: (category) => {
            return (dispatch,getState) => {

                dispatch({
                    type: types.CATEGORY_ACTIVATE,
                    payload:{
                        category: category
                    }
                });

                let categoryId = 0; // uncategorized
                if(category != null){ 
                    categoryId = category.id; 
                }

                return ajax({
                    route: `${backend.gallery.display.workCategories}/${categoryId}/works`,
                    method: 'get',
                    logTitle:{
                        fetch: `Category ${categoryId} works: `
                    },
                    callbacks:{
                        onSuccess: (data) => {
                            let works = data;
                            if(categoryId == getState().galleryManager.active.category.id){ // prevent setting works if other category is activated during request
                                dispatch(actions.set.categoryWorks(works));
                            }
                        }
                    }
                });

            }
        },
    
        image: (image) => ({
            type: types.IMAGE_ACTIVATE,
            payload:{
                image: image
            }
        }),
    }
}

export default actions;