import React, { useEffect, useReducer } from 'react'
import update from 'immutability-helper';
import _ from 'lodash';
import logger from 'Logging';
import axios from 'axios';

const defaultAjaxConfig = {
    route: '',
    method: 'get',
    callbacks: {
        onSuccess: (data) => (null),
        onError: (code, data) => (null),
    },
    manipulate: (data) => { return data }
}

/**
 * @param _ajaxConfig ajaxConfig without callbacks 
 * 
 * {
 * 
 *      @param route backend route
 *      @param method 'get'/'post'
 *      @param callbacks:{
            onSuccess: (data) => (null),
            onError: (code, data) => (null),
        },
 *      @param manipulate(data) => return manipulated data to onSuccess
 *
 * }
 * @param formValues 
 * @returns 
 */
export function ajax(_ajaxConfig, formValues = null, axiosConfig=null) {

    const ajaxConfig = _.mergeWith({}, defaultAjaxConfig, _ajaxConfig)

    let formData = null;
    if (formValues != null) {
        formData = new FormData();

        Object.keys(formValues).forEach((key) => {
            if(Array.isArray(formValues[key])){ // simple 1d array of objs
                formValues[key].forEach(obj=>{
                    formData.append(key+'[]', obj);
                });
                /*for (let i = 0 ; i < formValues[key].length ; i++) {
                    formData.append("images[]", formValues[key][i]);
                }*/
            }
            else{
                formData.append(key, formValues[key])
            }
        })

        logger.Submitting('Submitting...', null, formValues)
    }

    return axios[ajaxConfig.method](ajaxConfig.route, formData, axiosConfig)
        .then(res => {
            let data = res.data;
            logger.Fetch(ajaxConfig.route, null, data);
            ajaxConfig.callbacks.onSuccess(ajaxConfig.manipulate(data));
        })
        .catch(err => {
            if (err.response) {
                logger.Response(err.response.status, null, err.response.data, ajaxConfig.route);
                ajaxConfig.callbacks.onError(err.response.status, err.response.data);
            }
        });
}

/** 
 * @param backendConfig ajaxConfig without callbacks 
 * 
 * {
 * 
 *      @param route backend route
 *      @param method 'get'/'post'
 * 
 *      @param manipulate(data) => return manipulated data to onSuccess
 *
 * }
 * 
 * @returns [request,response] : 
 * 
 *  request(values, callbacks) -> formValues, onSuccess/Error (code, data) from axios
 *  response { data, loaded }
 */
export function useBackend(backendConfig, axiosConfig=null) {

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_RESPONSE':
                {
                    return update(state, {
                        data: { $set: action.data },
                        loaded: { $set: action.loaded }
                    });
                }
            default: {
                return state;
            }
        }
    }

    const [response, dispatch] = useReducer(reducer, {
        data: null,
        loaded: false,
    });

    const request = (formValues, callbacks) => {

        dispatch({
            type: 'SET_RESPONSE',
            data: null,
            loaded: false
        });

        const ajaxConfig = update(backendConfig, {
            callbacks: {
                $auto: {
                    onSuccess: {
                        $set: (respData) => {
                            dispatch({
                                type: 'SET_RESPONSE',
                                data: respData,
                                loaded: true
                            });
                            callbacks.onSuccess(respData);
                        }
                    },
                    onError: {
                        $set: (errCode, errData) => {
                            dispatch({
                                type: 'SET_RESPONSE',
                                data: errData,
                                loaded: true
                            });
                            callbacks.onError(errCode, errData);
                        }
                    }
                }
            }
        });

        ajax(ajaxConfig, formValues, axiosConfig);
    }

    return [request, response];
}