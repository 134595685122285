import { combineReducers } from 'redux'

import galleryManagerReducer from './gallery_manager/galleryManagerReducer'
import authReducer from './auth/authReducer'

const reducers = combineReducers({
    galleryManager: galleryManagerReducer,
    auth: authReducer,
});

export default reducers;
