// core
import React, { useEffect, useState } from 'react'
import log from 'Logging'
import $ from 'jquery'
import UIkit from 'uikit';

// hooks
import { useLog, useRedux } from 'hooks/components'
import { backend } from 'routes';

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/gallery_manager/actions'

// components
import LazyImage from 'components/LazyImage'
import MyForm, { Field, Check, PopoverForm, Editable, SingleButtonAction } from 'components/MyForm';
import RequireRole from 'components/pages/reserved/RequireRole'

// auth
import roles from 'state/auth/roles';


const ToolBar = ({ image, activateOnDelete, redux }) => {

    const logConfig = {
        name: `ToolBar`
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = redux;

    return (
        <nav className="uk-navbar-container" uk-navbar="" >

            <div className="uk-navbar-left">

                <p href="" className="uk-navbar-item uk-logo">Tools</p>

                {/* for future addings*/}
                <ul className="uk-navbar-nav">
                    <li className="uk-active"><a href="#"></a></li>
                </ul>

            </div>

            <div className="uk-navbar-right">
                <button className="uk-button-danger uk-button-small button-iconed" type="button"> <span uk-icon="icon: trash; ratio: 0.7" /> Elimina </button>
                <div className="drop-confirm" uk-drop="mode: click; pos: bottom-left; offset: 0">
                    <SingleButtonAction legend="Confirm?" backendRoute={`${backend.reserved.manage.gallery.delete.image}/${image.id}`} ukIcon='trash' ukClass='uk-button-danger' stateCallback={() => { UIkit.drop('.drop-confirm').hide(false); dispatch(actions.delete.image(image)); dispatch(actions.activate.image(activateOnDelete)); }} />
                </div>
            </div>

        </nav>
    )
}

const arrow_disabled = {
    visibility: 'hidden',
    pointerEvents: 'none'
}

const dummyImage = (type) => ({
    id: type,
    download_url: null,
    thumb_url: null
})

function LightBox({ startingImage, redux }) {
    const logConfig = {
        name: `LightBox`
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = redux;

    const [currentImage, setCurrentImage] = useState(startingImage);

    useEffect(() => {
        if (currentImage.id != startingImage.id) {
            setCurrentImage(startingImage)
        }
    }, [startingImage])

    const index = galleryManager.active.work.images.findIndex((img) => img == currentImage); // index of image in current work images array

    const prevImage = index == 0 ? dummyImage('prev') : galleryManager.active.work.images[index - 1];
    const nextImage = index + 1 == galleryManager.active.work.images.length ? dummyImage('next') : galleryManager.active.work.images[index + 1];

    function backward() {
        if (prevImage.download_url) {
            setCurrentImage(prevImage);
        }
    }
    function forward() {
        if (nextImage.download_url) {
            setCurrentImage(nextImage);
        }
    }
    function close(){
        dispatch(actions.activate.image(null)) // exit and unmount viewer
    }

    function handleKeyDown(event) {
        if (event.isComposing || event.keyCode === 229) {
            console.log("ime", event.keyCode, event.code);
            return;
        }
        //console.log(event.keyCode, event.code, event.key);
        switch (event.key) {
            case "Escape": {
                close();
                return;
            }
            case "ArrowLeft": {
                backward();
                return;
            }
            case "ArrowRight": {
                forward();
                return;
            }
            default:
                return;
        }
    }


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    });

    return (
        <>
            <div className="lightbox">

                <RequireRole role={roles.admin}>
                    <ToolBar image={currentImage} activateOnDelete={nextImage.download_url ? nextImage : prevImage} redux={[galleryManager, dispatch]} />
                </RequireRole>

                <div className="lightbox-controls">
                    <div id="arrow-prev" style={prevImage.download_url == null ? arrow_disabled : null} onClick={backward}><span uk-icon="icon: chevron-left; ratio: 1.5" /></div>
                    <div id="lightbox-close" onClick={close}><span uk-icon="icon: close; ratio: 1.5" /></div>
                    <div id="arrow-next" style={nextImage.download_url == null ? arrow_disabled : null} onClick={forward}><span uk-icon="icon: chevron-right; ratio: 1.5" /></div>
                </div>

                <div className='images' style={{ backgroundImage: `url(${currentImage.thumb_url})` }}>

                    <div className='display'>
                        <LazyImage key={currentImage.id} id={currentImage.id} src={currentImage.download_url} placeholder={'hide'} />
                    </div>

                    <div className="next">
                        <LazyImage key={nextImage.id} id={nextImage.id} src={nextImage.download_url} placeholder={'hide'} forceLoad={true} />
                    </div>

                    <div className="prev">
                        <LazyImage key={prevImage.id} id={prevImage.id} src={prevImage.download_url} placeholder={'hide'} forceLoad={true} />
                    </div>

                </div>

            </div>
        </>
    )
}

function Viewer() {

    const logConfig = {
        name: `Viewer`
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => a.active.image.id !== b.active.image.id // rerender only if image changes
    }, logConfig);

    return (
        <>
            { galleryManager.active.image.id !== null &&
                <LightBox key={galleryManager.active.image.id} startingImage={galleryManager.active.image} redux={[galleryManager, dispatch]} />
            }
        </>
    )
}

export default Viewer
