// core
import React, { useEffect, useState } from 'react'
import log from 'Logging';
import axios from 'axios';

// components

import { Formik } from 'formik';
import * as yup from 'yup';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'

import { Link } from "react-router-dom";

import MyForm, {Field, Check} from 'components/MyForm';

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/auth/actions'

// hooks
import { useRedux, useLog, useAlert, usePageTitle } from 'hooks/components';
import { getLocalStorageItem, removeLocalStorageItem } from 'hooks/storage';
import { useBackend } from 'hooks/ajax';
import routes, {backend} from 'routes';

const schema = yup.object().shape({
    newPassword: yup.string().required().min(8, 'Min 8 chars long'),
    confirmPassword: yup.string().when("newPassword", {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf(
            [yup.ref("newPassword")],
            "Le password devono combaciare"
        )
    })
});

const ResetPassword = ({ tokenVerifiedData, token }) => {

    const username = tokenVerifiedData.username;

    const logConfig = {
        name: 'ResetPassword'
    };

    useLog(logConfig)

    const [request, response] = useBackend({
        route: backend.reserved.public.tokenized.resetPassword + `/${token}`,
        method: 'post'
    }, logConfig)

    usePageTitle(`Geotek - Recupero Password`);

    return (
        <div className='root'>

            <MyForm
                legend={`Resetta password per ${username}`}
                schema={schema}
                request = {request}
                initialValues={{
                    newPassword: "",
                    confirmPassword: ""
                }}
                submitButton={{title: 'Reset Password'}}
                onComplete={{
                    redirect: 'link',
                    linkTitle: 'Login',
                    path: routes.reserved.public.login,
                    messages:{
                        success: 'Password resettata',
                        includeErrorData: true
                    }
                }}
            >

                <Field
                    id='1'
                    type="password"
                    placeholder="Nuova Password"
                    name="newPassword"
                />

                <Field
                    id='2'
                    type="password"
                    placeholder="Conferma Password"
                    name="confirmPassword"
                />

            </MyForm>

        </div>
    )
}

export default ResetPassword;
