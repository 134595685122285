import {motion} from 'framer-motion'
import { HashLink } from 'react-router-hash-link';

import LegalFooter from 'components/pages/LegalFooter';

import favicon from 'images/favicon.ico';

const SubPageHeader = ({title, restoreScrollHash='', restoreScrollOffset=0}) => {

    return (
        <nav className="uk-navbar-container subpage-header" uk-navbar="" >

            <div className="uk-navbar-left">

                <ul className="uk-navbar-nav">
                    <li className='home-link'>
                        <HashLink
                            to={`/#${restoreScrollHash}`}
                            scroll={(el) => { 
                                // el.scrollIntoView({ behavior: 'instant', block: 'start' });
                                const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
                                const yOffset = restoreScrollOffset; 
                                window.scrollTo({ top: yCoordinate + yOffset, behavior: 'instant' });  
                            }}
                        >
                            <span uk-icon="icon: arrow-left; ratio: 1.5" className="uk-icon back-icon" />
                            <span><img src={favicon}/></span>
                        </HashLink>
                    </li>
                </ul>

            </div>

            <div className="header-title">
                <p className="uk-navbar-item uk-logo">{title}</p>
            </div>

        </nav>
    )
}

const subPageWrapperVariants = {
    initial:{
        x: "100vw",
        transition: { ease: 'easeInOut'}
    },
    enter: {
        x: "0vw",
        transition: { ease: 'easeInOut'}
    },
    exit: {
        x: "100vw",
        transition: { ease: 'easeInOut'}
    }
}

const SubPage = ({children, ...rest}) => {
    return (
        <motion.div className='subpage-wrapper'
            variants={subPageWrapperVariants}
            initial='initial'
            animate='enter'
            exit='exit'
        >
            <SubPageHeader {...rest}/>
            {children}
            <LegalFooter />
        </motion.div>
    )
}

export default SubPage
export {SubPageHeader}
