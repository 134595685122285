// core
import { useEffect, useState } from 'react'
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import { tiers } from 'Logging'
import update, { extend } from 'immutability-helper';

import {AnimatePresence} from 'framer-motion'

// store
import actions from 'state/auth/actions'


// pages
import GalleryManager from 'components/pages/reserved/gallery_manager';
import Login from 'components/pages/reserved/public/login';
import ForgotPassword from 'components/pages/reserved/public/forgot_password';
import ResetPassword from 'components/pages/reserved/public/tokenized/reset_password';
import Register from 'components/pages/reserved/public/tokenized/register';
import Home from 'components/pages/public/home';
import NotFound from 'components/pages/public/not_found';
import Legal from 'components/pages/public/legal/Legal';

// routes
import routes, {backend} from 'routes'
import {ReservedRoute, TokenizedRoute, TokenizedComponent, LoginRoute} from 'components/routes';

// auth
import roles from 'state/auth/roles'

// hooks
import { useRedux, useLog } from 'hooks/components';
import { getLocalStorageItem } from 'hooks/storage';

// resources
import './App.css';


// ------ JS SETUPS -------

// TODO: change axios base url according to server
if(process.env.NODE_ENV == 'production'){
  axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_BASE_URL}`; // backend domain
}
else{ // dev
  //`http://${window.location.hostname}:8000`;
  //'http://localhost:8000';
  //'https://www.geoteksystem.it 
  axios.defaults.baseURL = `http://${window.location.hostname}:8000`;
}

axios.defaults.withCredentials = true // pass phpsessid with cors

// immutable helper setup
extend('$auto', function(value, object) {
  return object ?
    update(object, value):
    update({}, value);
});
extend('$autoArray', function(value, object) {
  return object ?
    update(object, value):
    update([], value);
});

// --------------------

function App() {

  const logConfig = {
    name: 'App',
    color: "aliceblue"
  };
  const reduxConfig = {
    selector: 'auth',
    reRender: (a, b) => {
      const userChanged = a.user.id !== b.user.id;
      return userChanged;
    } // rerender only if user log in/out
  }
  
  const log = useLog(logConfig)
  const [auth, dispatch] = useRedux(reduxConfig, logConfig);

  const location = useLocation();

  return (

      <AnimatePresence initial={false} exitBeforeEnter>

          <Switch location={location} key={location.key}>

          <Route exact path='/' component={Home} />

          <Route exact path={routes.public.gallery} render={() => <GalleryManager headerTitle='Galleria' displayOnly={true}/>}/>

            <LoginRoute 
              redux={[auth,dispatch]} 
              role={roles.admin} 
              path={routes.reserved.public.login} 
              redirectTo={routes.reserved.gallery_manager} 
            />

            <ReservedRoute 
              redux={[auth,dispatch]}
              role={roles.admin} 
              component={GalleryManager} 
              path={routes.reserved.gallery_manager} 
              redirectTo={routes.reserved.public.login}  
            />

            <Route exact path={routes.reserved.public.forgot_password} component={ForgotPassword} />

            <Route exact path={routes.reserved.public.tokenized.reset_password} render={() => (
              <TokenizedComponent component={ResetPassword} verifyTokenPath={backend.reserved.public.tokenized.resetPassword} />
            )} />

            <Route exact path={routes.reserved.public.tokenized.register} render={() => (
              <TokenizedComponent component={Register} verifyTokenPath={backend.reserved.public.tokenized.register} />
            )} />

            <Route exaxt path={routes.public.legal} component={Legal} />

          <Route component={NotFound}/>

        </Switch>

      </AnimatePresence>

  );
}

  export default App;
