const frontend = {
    reserved:{
        public:{
            login: '/reserved/login',
            forgot_password: '/reserved/forgotPassword',
            tokenized:{
                reset_password: '/reserved/resetPassword/:token',
                register: '/reserved/register/:token'
            }           
        },
        gallery_manager: '/reserved/galleryManager',
    },
    public:{
        not_found: '/notFound',
        gallery: '/gallery',
        legal: '/legal'
    }
}

const backend = {
    reserved:{
        manage:{
            account:{
                generateAdmin: `/reserved/manage/account/generateAdmin`,
                logout: `/reserved/manage/account/logout`,
                changePassword: "/reserved/manage/account/changePassword",
                deleteMe: "/reserved/manage/account/delete/me"
            },
            gallery:{
                add:{
                    workCategory: '/reserved/manage/gallery/add/workCategory',
                    work: '/reserved/manage/gallery/add/work',
                    images: '/reserved/manage/gallery/add/images'
                },
                delete:{
                    work: '/reserved/manage/gallery/delete/work',
                    workCategory: '/reserved/manage/gallery/delete/workCategory',
                    image: '/reserved/manage/gallery/delete/image',
                    images: '/reserved/manage/gallery/delete/images'
                },
                rename:{
                    work: '/reserved/manage/gallery/rename/work',
                    workCategory: '/reserved/manage/gallery/rename/workCategory'
                },
                edit:{
                    work: "/reserved/manage/gallery/edit/work" // {workId}/<description|category>
                }
            }
        },
        public:{
            login: "reserved/public/login",
            forgotPassword: '/reserved/public/password/forgot',
            tokenized:{
                resetPassword: '/reserved/public/tokenized/password/reset',
                register: '/reserved/public/tokenized/register'
            }
        }
    },
    gallery: {
        display:{
            workCategories: `gallery/display/workCategories`,
            works: `gallery/display/works`,
        }        
    }
}

export default frontend;
export { backend };