export const tiers = {
    persist: 'persist',
    loaded: 'loaded',
    rendering: 'rendering',
    equating: 'equating',
    unmounting: 'unmounting',
    fetch: 'fetch',
    submitting: 'submitting',
    response: 'response'
}

const enabledTiers = {
    [tiers.rendering]: false,
    [tiers.equating]: false,
    [tiers.loaded]: false,
    [tiers.persist]: true,
    [tiers.unmounting]: false,
    [tiers.fetch]: true,
    [tiers.submitting]: true,
    [tiers.response]: true,
}

class Logger {

/* 
    constructor(tiersList) {

        this.tiers = [];

        for (const [key, value] of Object.entries(tiersList)) {
            if (value)
                this.tiers.push(key);
        }
    } */

    constructor() {

        this.enabled = false;

        if(process.env.NODE_ENV == 'development'){
            this.enabled = true;
        }
    }

    log(tier, tierColors, msg, color = 'white', ...others) {
        if (enabledTiers[tier] && this.enabled) {
            const style = "border-radius: 2px; \
                            padding: 3px; \
                            background-color: "+ tierColors[0] + "; \
                            color: "+ tierColors[1]

            console.log("%c" + tier + "%c\t" + msg, style, "color: " + color, ...others);
        }

    }

    Rendering(msg, color, ...others) { this.log.apply(this, [tiers.rendering, ['yellow', 'black'], ...arguments]) }
    Equating(msg, color, ...others) { this.log.apply(this, [tiers.equating, ['blue', 'white'], ...arguments]) }
    Unmounting(msg, color, ...others) { this.log.apply(this, [tiers.unmounting, ['lightcoral', 'white'], ...arguments]) }
    Fetch(msg, color, ...others) { this.log.apply(this, [tiers.fetch, ['cadetblue', 'white'], ...arguments]) }
    Submitting(msg, color, ...others) { this.log.apply(this, [tiers.submitting, ['mediumspringgreen', 'black'], ...arguments]) }
    Response(msg, color, ...others) { this.log.apply(this, [tiers.response, ['crimson', 'white'], ...arguments]) }
    Loaded(msg, color, ...others) { this.log.apply(this, [tiers.loaded, ['chocolate', 'white'], ...arguments]) }
    Persist(msg, color, ...others) { this.log.apply(this, [tiers.persist, ['slategray', 'white'], ...arguments]) }
}

const log = new Logger();

export default log;