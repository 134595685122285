// core
import React, { useEffect, useState } from 'react'
import Log, { tiers } from 'Logging';
import axios from 'axios';

// components

import { Formik } from 'formik';
import * as yup from 'yup';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'

import { Link } from "react-router-dom";

import ConditionalAlert from 'components/ConditionalAlert'
import MyForm, { Field } from 'components/MyForm'

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/auth/actions'

// hooks
import { useRedux, useLog, useAlert, usePageTitle } from 'hooks/components';
import { getLocalStorageItem, removeLocalStorageItem } from 'hooks/storage';
import { useBackend } from 'hooks/ajax';
import routes, { backend } from 'routes';

const schema = yup.object().shape({
    password: yup.string().required().min(8, 'Minimo 8 caratteri'),
    confirmPassword: yup.string().required().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf(
            [yup.ref("password")],
            "Le password devono combaciare"
        )
    }),
    email: yup.string().required().email()
});

const Register = ({ tokenVerifiedData, token }) => {

    const logConfig = {
        name: 'Register'
    };

    const log = useLog(logConfig)

    const [request, response] = useBackend({
        route: backend.reserved.public.tokenized.register + `/${token}`,
        method: 'post'
    }, logConfig)

    usePageTitle(`Geotek - Registrazione nuovo Amministratore`);

    return (
        <div className='root'>
            <MyForm
                legend="Registrazione"
                schema={schema}
                request={request}
                initialValues={{
                    email: '',
                    password: '',
                }}
                submitButton={{ title: 'Registrati' }}
                onComplete={{
                    redirect: 'link',
                    linkTitle: 'Login',
                    path: routes.reserved.public.login,
                    messages: {
                        success: 'Registrazione completata',
                        includeErrorData: true
                    }
                }}
                md={6} lg={4}
            >

                <Field
                    id='0'
                    type="text"
                    placeholder="Name"
                    name="firstName"
                />

                <Field
                    id='1'
                    type="text"
                    placeholder="Email"
                    name="email"
                />

                <Field
                    id='2'
                    type="password"
                    placeholder="Password"
                    name="password"
                />

                <Field
                    id='3'
                    type="password"
                    placeholder="Conferma Password"
                    name="confirmPassword"
                />

            </MyForm>
        </div>
    )
}

export default Register;
