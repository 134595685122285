import * as types from './actionsTypes';
import log from 'Logging';
import axios from 'axios';
import _ from 'lodash';

import routes, {backend} from 'routes';
import { removeLocalStorageItem, setLocalStorageItem } from 'hooks/storage';
import { ajax } from 'hooks/ajax'

const actions = {
    
    setUser: (user) => ({
        type: types.SET_USER,
        payload:{
            user: user
        }
    }),
    
    authenticate: ( formValues=null, callbacks ) => {

        const defaultCallbacks = {
            onSuccess: (data) => { },
            onError: (code, data) => { }
        }
        const _callbacks = _.mergeWith({}, defaultCallbacks, callbacks)

        return (dispatch) => {
            const ajaxConfig = {
                route: backend.reserved.public.login,
                method: 'post',
                logTitle:{
                    fetch: `Logged In Response: `,
                    error: "Authentication Failed"
                },
                callbacks:{
                    onSuccess: (data) => {
                        dispatch(actions.setUser(data));
                        _callbacks.onSuccess();
                    },
                    onError: (code, data) => {
                        dispatch(actions.setUser(null));
                        if(formValues == null){ // was checking for session
                            setLocalStorageItem('login_alert', {variant: "warning", msg: "Sessione scaduta"})
                        }
                        _callbacks.onError();
                    },
                }
            }
            return ajax(ajaxConfig, formValues); // if formData == null , phpsessid is the token
        }
    },    
    
    logout: () => {
        return (dispatch) => { 

            function callback (){
                removeLocalStorageItem('user', 'Removed stored user');
                dispatch(actions.setUser(null));
            }

            const ajaxConfig = {
                route: backend.reserved.manage.account.logout,
                method: 'post',
                callbacks:{
                    onSuccess: (data) => {
                        callback();
                    },
                    onError: (code, data) => {
                        callback();
                    }
                }
            }
            return ajax(ajaxConfig);
        }
    }
}

export default actions;