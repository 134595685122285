import {Link} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import routes from 'routes'

const LegalFooter = () => {
    return(
        <div className='legal-footer'>
            <div className='row'>
                <div className='col'><span>Geotek © {new Date().getFullYear()}</span></div>
                <div className='col'><span>P.IVA: 05798060827</span></div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col'>
                    <span>
                        <HashLink to={routes.public.legal+'/#'} scroll={(el) => { 
                            window.scrollTo({ top: 0, behavior: 'instant' });  
                        }}> Normative, Informazioni e Agevolazioni fiscali</HashLink> 
                    </span>  
                </div>                              
            </div>
        </div>
    )
}

export default LegalFooter;