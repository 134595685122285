import * as types from './actionsTypes'

import update from 'immutability-helper';

const dummyImages = [
    { 
   "id": "0",
   "author": "Alejandro Escamilla",
   "width": 5616,
   "height": 3744,
   "url": "https://unsplash.com/photos/yC-Yzbqy7PY",
   "download_url": "https://picsum.photos/id/0/5616/3744",
   isSelected: false
   },
    { 
   "id": "1",
   "author": "Alejandro Escamilla",
   "width": 5616,
   "height": 3744,
   "url": "https://unsplash.com/photos/LNRyGwIJr5c",
   "download_url": "https://picsum.photos/id/1/5616/3744",
   isSelected: false
   },
    { 
   "id": "10",
   "author": "Paul Jarvis",
   "width": 2500,
   "height": 1667,
   "url": "https://unsplash.com/photos/6J--NXulQCs",
   "download_url": "https://picsum.photos/id/10/2500/1667",
   isSelected: false
   },
    { 
   "id": "100",
   "author": "Tina Rataj",
   "width": 2500,
   "height": 1656,
   "url": "https://unsplash.com/photos/pwaaqfoMibI",
   "download_url": "https://picsum.photos/id/100/2500/1656",
   isSelected: false
   },
    { 
   "id": "1000",
   "author": "Lukas Budimaier",
   "width": 5626,
   "height": 3635,
   "url": "https://unsplash.com/photos/6cY-FvMlmkQ",
   "download_url": "https://picsum.photos/id/1000/5626/3635",
   isSelected: false
   },
    { 
   "id": "1001",
   "author": "Danielle MacInnes",
   "width": 5616,
   "height": 3744,
   "url": "https://unsplash.com/photos/1DkWWN1dr-s",
   "download_url": "https://picsum.photos/id/1001/5616/3744",
   isSelected: false
   },
    { 
   "id": "1002",
   "author": "NASA",
   "width": 4312,
   "height": 2868,
   "url": "https://unsplash.com/photos/6-jTZysYY_U",
   "download_url": "https://picsum.photos/id/1002/4312/2868",
   isSelected: false
   },
    { 
   "id": "1003",
   "author": "E+N Photographies",
   "width": 1181,
   "height": 1772,
   "url": "https://unsplash.com/photos/GYumuBnTqKc",
   "download_url": "https://picsum.photos/id/1003/1181/1772",
   isSelected: false
   },
    { 
   "id": "1004",
   "author": "Greg Rakozy",
   "width": 5616,
   "height": 3744,
   "url": "https://unsplash.com/photos/SSxIGsySh8o",
   "download_url": "https://picsum.photos/id/1004/5616/3744",
   isSelected: false
   },
    { 
   "id": "1005",
   "author": "Matthew Wiebe",
   "width": 5760,
   "height": 3840,
   "url": "https://unsplash.com/photos/tBtuxtLvAZs",
   "download_url": "https://picsum.photos/id/1005/5760/3840",
   isSelected: false
   },
    { 
   "id": "1006",
   "author": "Vladimir Kudinov",
   "width": 3000,
   "height": 2000,
   "url": "https://unsplash.com/photos/-wWRHIUklxM",
   "download_url": "https://picsum.photos/id/1006/3000/2000",
   isSelected: false
   },
    { 
   "id": "1008",
   "author": "Benjamin Combs",
   "width": 5616,
   "height": 3744,
   "url": "https://unsplash.com/photos/5L4XAgMSno0",
   "download_url": "https://picsum.photos/id/1008/5616/3744",
   isSelected: false
   },
    { 
   "id": "1009",
   "author": "Christopher Campbell",
   "width": 5000,
   "height": 7502,
   "url": "https://unsplash.com/photos/CMWRIzyMKZk",
   "download_url": "https://picsum.photos/id/1009/5000/7502",
   isSelected: false
   },
    { 
   "id": "101",
   "author": "Christian Bardenhorst",
   "width": 2621,
   "height": 1747,
   "url": "https://unsplash.com/photos/8lMhzUjD1Wk",
   "download_url": "https://picsum.photos/id/101/2621/1747",
   isSelected: false
   },
    { 
   "id": "1010",
   "author": "Samantha Sophia",
   "width": 5184,
   "height": 3456,
   "url": "https://unsplash.com/photos/NaWKMlp3tVs",
   "download_url": "https://picsum.photos/id/1010/5184/3456",
   isSelected: false
   },
    { 
   "id": "1011",
   "author": "Roberto Nickson",
   "width": 5472,
   "height": 3648,
   "url": "https://unsplash.com/photos/7BjmDICVloE",
   "download_url": "https://picsum.photos/id/1011/5472/3648",
   isSelected: false
   },
    { 
   "id": "1012",
   "author": "Scott Webb",
   "width": 3973,
   "height": 2639,
   "url": "https://unsplash.com/photos/uAgLGG1WBd4",
   "download_url": "https://picsum.photos/id/1012/3973/2639",
   isSelected: false
   },
    { 
   "id": "1013",
   "author": "Cayton Heath",
   "width": 4256,
   "height": 2832,
   "url": "https://unsplash.com/photos/D8LcRLwZyPs",
   "download_url": "https://picsum.photos/id/1013/4256/2832",
   isSelected: false
   },
    { 
   "id": "1014",
   "author": "Oscar Keys",
   "width": 6016,
   "height": 4000,
   "url": "https://unsplash.com/photos/AmPRUnRb6N0",
   "download_url": "https://picsum.photos/id/1014/6016/4000",
   isSelected: false
   },
    { 
   "id": "1015",
   "author": "Alexey Topolyanskiy",
   "width": 6000,
   "height": 4000,
   "url": "https://unsplash.com/photos/-oWyJoSqBRM",
   "download_url": "https://picsum.photos/id/1015/6000/4000",
   isSelected: false
   },
    { 
   "id": "1016",
   "author": "Philippe Wuyts",
   "width": 3844,
   "height": 2563,
   "url": "https://unsplash.com/photos/_h7aBovKia4",
   "download_url": "https://picsum.photos/id/1016/3844/2563",
   isSelected: false
   },
    { 
   "id": "1018",
   "author": "Andrew Ridley",
   "width": 3914,
   "height": 2935,
   "url": "https://unsplash.com/photos/Kt5hRENuotI",
   "download_url": "https://picsum.photos/id/1018/3914/2935",
   isSelected: false
   },
    { 
   "id": "1019",
   "author": "Patrick Fore",
   "width": 5472,
   "height": 3648,
   "url": "https://unsplash.com/photos/V6s1cmE39XM",
   "download_url": "https://picsum.photos/id/1019/5472/3648",
   isSelected: false
   },
    { 
   "id": "102",
   "author": "Ben Moore",
   "width": 4320,
   "height": 3240,
   "url": "https://unsplash.com/photos/pJILiyPdrXI",
   "download_url": "https://picsum.photos/id/102/4320/3240",
   isSelected: false
   },
    { 
   "id": "1020",
   "author": "Adam Willoughby-Knox",
   "width": 4288,
   "height": 2848,
   "url": "https://unsplash.com/photos/_snqARKTgoc",
   "download_url": "https://picsum.photos/id/1020/4288/2848",
   isSelected: false
   },
    { 
   "id": "1021",
   "author": "Frances Gunn",
   "width": 2048,
   "height": 1206,
   "url": "https://unsplash.com/photos/8BmNurlVR6M",
   "download_url": "https://picsum.photos/id/1021/2048/1206",
   isSelected: false
   },
    { 
   "id": "1022",
   "author": "Vashishtha Jogi",
   "width": 6000,
   "height": 3376,
   "url": "https://unsplash.com/photos/bClr95glx6k",
   "download_url": "https://picsum.photos/id/1022/6000/3376",
   isSelected: false
   },
    { 
   "id": "1023",
   "author": "William Hook",
   "width": 3955,
   "height": 2094,
   "url": "https://unsplash.com/photos/93Ep1dhTd2s",
   "download_url": "https://picsum.photos/id/1023/3955/2094",
   isSelected: false
   },
    { 
   "id": "1024",
   "author": "Мартин Тасев",
   "width": 1920,
   "height": 1280,
   "url": "https://unsplash.com/photos/7ALI0RYyq6s",
   "download_url": "https://picsum.photos/id/1024/1920/1280",
   isSelected: false
   },
    { 
   "id": "1025",
   "author": "Matthew Wiebe",
   "width": 4951,
   "height": 3301,
   "url": "https://unsplash.com/photos/U5rMrSI7Pn4",
   "download_url": "https://picsum.photos/id/1025/4951/3301",
   isSelected: false
   }
];
const categories = [
    {
        id: '1',
        name: 'Category #1'
    },
    {
        id: '2',
        name: 'Category #2'
    },
    {
        id: '3',
        name: 'Category #3'
    },
    {
        id: '4',
        name: 'Category #4'
    },
    {
        id: '5',
        name: 'Category #5'
    },
    {
        id: '6',
        name: 'Category #6'
    }
];
const works = [
    {
        id: '1',
        name: 'Work #1',
        "download_url": "https://picsum.photos/id/1003/1181/1772"
    },
    {
        id: '2',
        name: 'Work #2',
        "download_url": "https://picsum.photos/id/0/5616/3744"
    },
    {
        id: '3',
        name: 'Work #3',
        "download_url": "https://picsum.photos/id/10/2500/1667"
    },
    {
        id: '4',
        name: 'Work #4',
        "download_url": "https://picsum.photos/id/100/2500/1656"
    },
    {
        id: '5',
        name: 'Work #5'
        ,"download_url": "https://picsum.photos/id/1000/5626/3635"
    },
    {
        id: '6',
        name: 'Work #6',
        "download_url": "https://picsum.photos/id/1002/4312/2868"
    }
];

// ---------

/**
 * CONVENTION: null -> still loading , [] -> empty
 */

const dummyCategory = {
    id: '-1',
    name: '',
    works: null
}

const uncategory = {
    id: '0',
    name: 'Nascosti',
}

const dummyWork = {
    id: null,
    name: null,
    images: null,
    selectedImages:[],
    isAtLeastOneImageSelected: false
}

const dummyImage = {
    id: null,
}

const defaultGalleryState = {
    galleryDisplayOnly: true,
    categories: null,
    active:{
        category: dummyCategory,
        work: dummyWork,
        image: dummyImage, //first clicked image, to activate the viewer
    }
}

const galleryManagerReducer = (state = defaultGalleryState, action) => {
        
    switch(action.type)
    {
        case types.IMAGE_SELECT:
        {
            const image = action.payload.image;

            if(action.payload.isSelected){ // selecting --> push id to selectedImages
                return update( state, {
                    active:{
                        work:{
                            selectedImages: {$push: [image]},
                            isAtLeastOneImageSelected: {$set: true} // selecting right now, obvious
                        }
                    }
                })
            }
            else{ // deselecting --> remove id from selectedImages
                const index = state.active.work.selectedImages.indexOf(image);
                return update( state, {
                    active:{
                        work:{
                            selectedImages:  {$splice: [[index, 1]] },
                            isAtLeastOneImageSelected: {$set: state.active.work.selectedImages.length > 1} // if only one image was selected that's the one we're removing
                        }
                    }
                })                
            }

        }
        case types.IMAGES_SELECT_ALL:
        {
            if(action.payload.isSelected){
                return update( state, {
                    active:{
                        work:{
                            selectedImages: {$set: state.active.work.images},
                            isAtLeastOneImageSelected: {$set: true}
                        }
                    }
                })
            }
            else{
                return update( state, {
                    active:{
                        work:{
                            selectedImages: {$set: [] },
                            isAtLeastOneImageSelected: {$set: false}
                        }
                    }
                })
            }
        }

        case types.CATEGORY_ACTIVATE:
        {
            const category = action.payload.category != null ? action.payload.category : uncategory;

            const newState = update( state, {
                active:{
                    category: {$set: category}
                }
            })

            return newState;
        }
        case types.WORK_ACTIVATE:
        {
            const work = action.payload.work == null ? dummyWork : action.payload.work;

            const newState = update( state, {
                active:{
                    work:{$set: work}
                }
            })

            const finalState = update( newState, {
                active:{
                    work:{
                        images:{$set: null}
                    }
                }
            })
            
            return finalState;
        }
        case types.IMAGE_ACTIVATE:
        {
            const image = action.payload.image ? action.payload.image : dummyImage;

            const newState = update( state, {
                active:{
                    image: {$set: image}
                }
            })
            
            return newState;
        }

        case types.CATEGORY_WORKS_SET:
        {
            
            const works = action.payload.works;

            const newState = update( state, {
                active:{
                    category:{
                        works: {$set: works}
                    }
                }
            })
            
            return newState;
        }
        case types.WORK_IMAGES_SET:{
            
            const images = action.payload.images;

            const newState = update( state, {
                active:{
                    work:{
                        images: {$set: images},
                        selectedImages: {$set: []}
                    }
                }
            })
            
            return newState;
        }
        case types.CATEGORIES_SET:{
            
            const newState = update( state, {
                categories: {$set: action.payload.categories}
            })
            
            return newState;
        }

        case types.WORK_RENAME:{

            const index = state.active.category.works.findIndex( (work) => work == action.payload.work );                

            const newState = update( state, {
                active:{
                    category:{
                        works: {
                            [index]:{
                                name: {$set: action.payload.newName}
                            }
                        }
                    }
                }
            })
            
            return newState;
        }
        case types.WORK_REDESCRIPT:{

            const index = state.active.category.works.findIndex((work) => work == action.payload.work);

            const newState = update( state, {
                active:{
                    work:{
                        description: {$set: action.payload.newDescription}
                    },
                    category:{
                        works: {
                            [index]:{
                                description: {$set: action.payload.newDescription}
                            }
                        }
                    }
                }
            })
            
            return newState;
        }
        case types.CATEGORY_RENAME:{

            const index = state.categories.findIndex( (cat) => cat == action.payload.category );                

            const newState = update( state, {
                categories:{
                    [index]:{
                        name: {$set: action.payload.newName}
                    }
                }
            })
            
            return newState;
        }

        case types.WORK_DELETE:{

            const index = state.active.category.works.findIndex( (work) => work == action.payload.work );                

            const newState = update( state, {
                active:{
                    category:{
                        works: {$splice: [[index, 1]] }
                    }
                }                
            })
            
            return newState;
        }
        case types.CATEGORY_DELETE:{

            const index = state.categories.findIndex( (cat) => cat == action.payload.category );                

            const newState = update( state, {
                categories: {$splice: [[index, 1]] }
            })
            
            return newState;
        }
        case types.IMAGE_DELETE:{

            const imagesIndex = state.active.work.images.findIndex( (img) => img.id == action.payload.image.id );                
            const selectedImagesIndex = state.active.work.selectedImages.indexOf(action.payload.image);                

            // if image was also selected, info about seleted images must be recalculated
            if(selectedImagesIndex >= 0){
                return update( state, {
                    active:{
                        work:{
                            images: {$splice: [[imagesIndex, 1]] },
                            selectedImages: {$splice: [[selectedImagesIndex, 1]] },
                            isAtLeastOneImageSelected: {$set: state.active.work.selectedImages.length > 1}
                        }
                    }                
                })
            }
            else{ // image was not selected so there's no info about
                return update( state, {
                    active:{
                        work:{
                            images: {$splice: [[imagesIndex, 1]] }
                        }
                    }                
                })
            }
        }
        case types.IMAGES_SELECTED_DELETE:{
            
            const images = [...state.active.work.images];
            state.active.work.selectedImages.forEach( si => {
                const index = images.findIndex(img => img.id == si.id);
                images.splice(index,1);
            } );

            const newState = update( state, {
                active:{
                    work:{
                        images: {$set: images},
                        selectedImages: {$set: []},
                        isAtLeastOneImageSelected: {$set: false}
                    }
                }                
            })

            return newState;
        }

        case types.GALLERY_DISPLAY_ONLY_SET:{
            
            const newState = update( state, {
                galleryDisplayOnly: {$set: action.payload.displayOnly}           
            })

            return newState;
        }

        default:
            return state;
    }
};

export default galleryManagerReducer;