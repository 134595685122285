import React, { useState, useEffect } from 'react';
import log from 'Logging'
import axios from 'axios';
import update from 'immutability-helper';

import { Dropdown, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import { Icon } from '@fluentui/react/lib/Icon';
import { Label } from '@fluentui/react/lib/Label';
import { Stack } from '@fluentui/react/lib/Stack';
import { IconButton } from '@fluentui/react/lib/Button';
import { ContextualMenuItemType, DirectionalHint } from '@fluentui/react/lib/ContextualMenu';
import { DefaultButton, CompoundButton } from '@fluentui/react/lib/Button';

import { Shimmer, ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';

import * as yup from 'yup';
import MyForm, {Field, Check} from 'components/MyForm';

import Modal from 'react-bootstrap/Modal'

import { useToast } from "@chakra-ui/react"

// auth
import roles from 'state/auth/roles'

// store
import actions from 'state/auth/actions'

// hooks
import { useRedux, useLog, useModal } from 'hooks/components';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'hooks/storage';
import { useBackend } from 'hooks/ajax';
import routes, { backend } from 'routes';

/*
function ReservedItem({children, user, role}){
  return user.roles.includes(role) ? <Menu.Item >{children}</Menu.Item> : null
}*/

function UserControl() {

  const logConfig = {
    name: 'UserControl'
  };
  const reduxConfig = {
    selector: 'auth',
    reRender: (a, b) => a.user.email !== b.user.email // rerender only if user is loaded 
  }

  useLog(logConfig);
  const [auth, dispatch] = useRedux(reduxConfig, logConfig);

  const [copyModal, setCopyModal] = useModal();
  const [formModal, setFormModal] = useModal();

  const menuActions = {

    logout: () => {
      dispatch(actions.logout())
    },

    generateAdmin: () => {

      setCopyModal.title("Link per registrazione di un nuovo admin");
      setCopyModal.show();
    },

    changePassword: () =>{
        setFormModal.key('changePassword');
        setFormModal.title('Cambia Password');
        setFormModal.show();
    },

    deleteAccount: () => {
      setFormModal.key('deleteAccount');
      setFormModal.title('Eliminare Account ?');
      setFormModal.show();
    }

  }

  let menuProps = {

    id: "userControl",
    directionalHint: DirectionalHint.bottomLeftEdge,
    items: [
      {
        itemProps: {
          className: "email",
        },
        key: 'email',
        itemType: ContextualMenuItemType.Header,
        text: auth.user.email
      },
      
      {
        key: 'actions',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          title: 'Azioni',
          items: [
            { key: 'generate_admin', text: 'Genera Admin', onClick: (e) => { menuActions.generateAdmin() }, }
          ],
        },
      },
      
      {
        key: 'account',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          title: 'Account',
          items: [
            {
              key: 'change_password',
              text: 'Cambia Password',
              onClick: (e) => { menuActions.changePassword() },
            },
            {
              key: 'delete_account',
              text: 'Elimina Account',
              onClick: (e) => { menuActions.deleteAccount() },
            }
          ],
        },
      },

      { key: 'divider_1', itemType: ContextualMenuItemType.Divider, className: "divider", },
      
      {
        className: "logout",
        onClick: (e) => { menuActions.logout() },
        itemType: ContextualMenuItemType.Normal,
        key: 'logout',
        text: 'Logout',
      }
    ],
  }

  if(auth.user.roles.includes(roles.root)){
    menuProps.items[2].sectionProps.items.splice(1,1); // hide delete account if root
  }

  return (
    <>
      {auth.user.firstName != null ?
        <DefaultButton text={auth.user.firstName} menuProps={menuProps} />
        :
        <Fabric className="userControl-shimmer">
          <Shimmer />
        </Fabric>
      }
      
      

      <GenerateAdminModal modal={copyModal} setModal={setCopyModal} />
      
      <Modal
        show={formModal.show}
        onHide={setFormModal.close}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formModal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          {
              formModal.key == 'changePassword' ? <ChangePasswordForm />
            : formModal.key == 'deleteAccount' ? <DeleteAccountForm username={auth.user.email} dispatch={dispatch} />
            : <></>
          }

        </Modal.Body>
      </Modal>

    </>
  )
}

const GenerateAdminModal = ({modal, setModal}) => {

  const [generateAdminRequest, generateAdminResponse] = useBackend({
    route: backend.reserved.manage.account.generateAdmin,
    method: 'post'
  })
  
  const callback = (data) => {
    setModal.content({ link: data.registrationUrl, msg: data.msg });
  }

  useEffect(()=>{
    if(modal.show){
      generateAdminRequest(null, {
        onSuccess: (data) => {
          callback(data)
        },
        onError: (code, data) => {
          callback(data)
        }
      })
    }    
  },[modal.show])

  const toast = useToast();
  const toastId = "cpcbt";

  return (
    <Modal
        show={modal.show}
        onHide={setModal.close}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generateAdminResponse.loaded ?
            <>
              <div>{modal.content.msg}</div>
              <div onClick={() => {
                navigator.clipboard.writeText(modal.content.link)

                if (!toast.isActive(toastId)) {
                  toast({
                    id: toastId,
                    title: "Copiato su clipboard",
                    status: "info",
                    position: "top",
                    duration: 1000,
                    isClosable: false,
                  })
                }
              }} > {modal.content.link} </div>
            </>
            : "LOADING..."}

        </Modal.Body>
      </Modal>
  )
}

const ChangePasswordForm = () => {

    const logConfig = {
        name: 'ChangePasswordForm'
    };

    useLog(logConfig)

    const [changePasswordRequest, changePasswordResponse] = useBackend({
      route: backend.reserved.manage.account.changePassword,
      method: 'post'
    },) 

    const schema = yup.object().shape({
      currentPassword: yup.string().required(),
      newPassword: yup.string().required().min(8, 'Minimo 8 caratteri'),
      confirmPassword: yup.string().when("newPassword", {
          is: val => (val && val.length > 0 ? true : false),
          then: yup.string().oneOf(
              [yup.ref("newPassword")],
              "Le password devono combaciare"
          )
      })
    });

    return (
            <MyForm
                schema={schema}
                request = {changePasswordRequest}
                initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: ""
                }}
                submitButton={{title: 'Cambia Password'}}
                onComplete={{
                    messages:{
                        success: 'Password changed succesfully',
                        includeErrorData: true
                    }
                }}
            >

                <Field
                    id='0'
                    type="password"
                    placeholder="Password Attuale"
                    name="currentPassword"
                />

                <Field
                    id='1'
                    type="password"
                    placeholder="Nuova Password"
                    name="newPassword"
                />

                <Field
                    id='2'
                    type="password"
                    placeholder="Conferma Password"
                    name="confirmPassword"
                />

            </MyForm>

    )
}

const DeleteAccountForm = ({username, dispatch}) => {

    const logConfig = {
      name: 'DeleteAccountForm'
  };

  useLog(logConfig)

  const [deleteAccountRequest, deleteAccountResponse] = useBackend({
    route: backend.reserved.manage.account.deleteMe,
    method: 'post',
  })

  const schema = yup.object().shape({
    currentPassword: yup.string().required(),
    deleteString: yup.string().test("match", "errore di battitura", (deleteString) => { return deleteString ==  "elimina"} )
  });

  return (
      <>

          <MyForm
              legend={`Eliminare account?`}
              schema={schema}
              request = {deleteAccountRequest}
              initialValues={{
                  deleteString: "",
                  currentPassword: ""
              }}
              submitButton={{title: 'Elimina Account'}}
              onComplete={{
                  messages:{
                      success: 'Account deleted',
                      includeErrorData: true
                  }
              }}
              callbacks={{
                onSuccess: (data) => { 
                  setLocalStorageItem('login_alert', {variant: "warning", msg: `Account ${username} eliminato`}) 
                  dispatch(actions.logout());
                }
              }}
          >

              <Field
                  id='1'
                  type="password"
                  placeholder="Password Attuale"
                  name="currentPassword"
              />

              <Field
                  id='0'
                  type="text"
                  placeholder='Scrivi "elimina"'
                  name="deleteString"
              />

          </MyForm>

      </>
    )
}

export default UserControl
