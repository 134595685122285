import logger from 'Logging'

export function getLocalStorageItem(name, onFound=(()=>{}), onNotFound=(()=>{}) ){
    
    const item = localStorage.getItem(name);
    if (item) {
        logger.Persist(`Found ${name} from localStorage`, null, item)
        onFound(JSON.parse(item))
        return JSON.parse(item);
    }
    else{
        logger.Persist(`${name} not found in localStorage`, null, item)
        onNotFound();
        return null;
    }
}

export function setLocalStorageItem(name, obj){
    
    const json = JSON.stringify(obj)
    localStorage.setItem(name, json)
    
    logger.Persist(`Persisted ${name} to localStorage`, null, json)
}

export function removeLocalStorageItem(name, msg){
    localStorage.removeItem(name);
    logger.Persist(msg, null);
}