// hooks
import { useLog, useRedux } from 'hooks/components'

// routes
import routes, { backend } from 'routes'

// auth
import roles from 'state/auth/roles'

const RequireRole = ({ children, role, fallback }) => {

    const logConfig = {
        name: `RequireRole ${role}`
    };
    const reduxConfig = {
        selector: 'auth',
        reRender: (a, b) => a.user.email !== b.user.email // rerender only if user is loaded 
    }

    useLog(logConfig);
    const [auth, dispatch] = useRedux(reduxConfig, logConfig);

    const [galleryManager, galleryDispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => (a.galleryDisplayOnly !== b.galleryDisplayOnly) // rerender for displayOnly changes
    }, logConfig);

    return (
        <>
            {auth.user.roles.includes(role) && !galleryManager.galleryDisplayOnly ? <>{children}</> : <>{fallback}</> }
        </>
    )
}

const RoleConditioner = ({ children,}) => {

    const logConfig = {
        name: `RoleConditioner`
    };
    const reduxConfig = {
        selector: 'auth',
        reRender: (a, b) => a.user.email !== b.user.email // rerender only if user is loaded 
    }

    useLog(logConfig);
    const [auth, dispatch] = useRedux(reduxConfig, logConfig);

    return (
        <>
            {children(auth.user.roles)}
        </>
    )
}

export {RoleConditioner};
export default RequireRole;