import SubPage from "components/pages/SubPage"
import { usePageTitle } from "hooks/components"

import './legal.css'

import agibilita from './pdf/agibilita.pdf'
import contributi from './pdf/contributi.pdf'
import costruire from './pdf/costruire.pdf'
import dia from './pdf/dia.pdf'
import durc from './pdf/durc.pdf'
import normative from './pdf/normative.pdf'
import sportello from './pdf/sportello.pdf'

const Legal = () =>{
    
    const title = 'Geotek - Normative, Informazioni e Agevolazioni fiscali';

    usePageTitle(title);
    
    return(
        <SubPage title={title}>
            <div className='container-xl'> 

                <div className="legal-table">
                    <table  className="uk-table uk-table-striped">
                        <thead>
                            <tr>
                                <th>Agevolazioni Fiscali</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td><a redirect="true" target="_blank" href="http://www.agenziaentrate.gov.it/wps/wcm/connect/b817df80426dc23e98b59bc065cef0e8/GUIDA+ristrutturazioni+per+internet.pdf?MOD=AJPERES&CACHEID=b817df80426dc23e98b59bc065cef0e8">Ristrutturazioni edilizie</a> - Una guida completa alle agevolazioni fiscali per le ristrutturazioni edilizie è disponibile sul sito dell’Agenzia delle Entrate</td></tr>
                            <tr><td><a redirect="true" target="_blank" href="http://www.agenziaentrate.gov.it/wps/wcm/connect/4d5ba980426dc22898a09bc065cef0e8/GUIDA+Risp_Energ.pdf?MOD=AJPERES&CACHEID=4d5ba980426dc22898a09bc065cef0e8">Risparmio energetico</a></td></tr>
                            <tr><td><a redirect="true" target="_blank" href="http://www.investireoggi.it/fisco/ristrutturazione-casa-agevolazioni-fiscali-2012-ecco-cosa-cambia/">Ristrutturazione casa</a></td></tr>
                        </tbody>
                    </table> 
                </div>

                <div className="legal-table" id="info">
                    <table className="uk-table uk-table-striped">
                        <thead>
                            <tr>
                                <th>Informazioni Utili</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td><a href={sportello} target="_blank" >Sportello unico dell'edilizia</a></td></tr>
                            <tr><td><a href={costruire} target="_blank" >Permesso di costruire</a></td></tr>								
                            <tr><td><a href={agibilita} target="_blank" >Certificato di agibilità</a></td></tr>
                            <tr><td><a href={contributi} target="_blank" >Contributi concessori</a></td></tr>
                            <tr><td><a href={dia} target="_blank" >Denuncia di inizio attività (DIA)</a></td></tr>
                            <tr><td><a href={durc} target="_blank" >Documento Unico di Regolarità Contributiva (DURC)</a></td></tr>
                            <tr><td><a href={normative} target="_blank" >Lista Normative</a></td></tr>
                        </tbody>                            
                    </table> 
                </div>

                <div className="legal-table">
                    <table className="uk-table uk-table-striped">
                        <thead>
                            <tr>
                                <th>Principali normative di riferimento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td><a redirect="true" target="_blank" href="http://www.comune.jesi.an.it/MV/leggi/l10-77.htm">Legge 10/77</a> - Norme per la edificabilità dei suoli</td></tr>
                            <tr><td><a redirect="true" target="_blank" href="http://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:legge:1978;457">Legge 457/78</a> - Norme per l'edilizia residenziale</td></tr>								
                            <tr><td><a redirect="true" target="_blank" href="http://www.regione.piemonte.it/sit/argomenti/pianifica/normativa/dwd/legge_47_85.pdf">Legge 28 febbraio 1985 n. 47</a> - Norme in materia di controllo dell’attività urbanistico-edilizia, sanzioni, recupero e sanatoria delle opere edilizie. (Gazz. Uff. 2 marzo 1985, n. 53 - Suppl. Ord.)</td></tr>
                            <tr><td><a redirect="true" target="_blank" href="http://www.handylex.org/stato/d140689.shtml">Decreto Ministeriale n. 236 del 14 giugno 1989</a> - Prescrizioni tecniche necessarie a garantire l'accessibilità, l'adattabilità e la visitabilità degli edifici privati e di edilizia residenziale pubblica sovvenzionata e agevolata, ai fini del superamento e dell'eliminazione delle barriere architettoniche (Pubblicato in suppl. ord. alla Gazzetta Ufficiale n.145 del 23 giugno 1989)</td></tr>
                            <tr><td><a redirect="true" target="_blank" href="http://www.handylex.org/stato/l231296.shtml">Legge 662/96, Art. 2, comma 60</a> - Misure di razionalizzazione della finanza pubblica - Norme in materia di edilizia (in part. denuncia inizio lavori eliminazione barriere architettoniche: casi particolari) (Pubblicata in G.U. 28 dicembre 1996, n. 303; S. O. n. 233)</td></tr>
                            <tr><td><a redirect="true" target="_blank" href="http://www.parlamento.it/parlam/leggi/deleghe/01378dla.htm">DPR 380/01</a> - Testo unico delle disposizioni legislative e regolamentari in materia edilizia, pubblicato nella Gazzetta Ufficiale n. 245 del 20 ottobre 2001 - Supplemento Ordinario n. 239 (Rettifica G.U. n. 47 del 25 febbraio 2002)</td></tr>
                        </tbody>

                    </table> 
                </div>
        
            </div>
            
        </SubPage>
    )
}

export default Legal;