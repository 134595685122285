import React from 'react'

function NotFound() {
    return (
        <div className='root'>
            Not Found
        </div>
    )
}

export default NotFound
