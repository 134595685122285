// core
import React , {useEffect, useState} from 'react'
import log from 'Logging';
import axios from 'axios';

// components

import { Formik } from 'formik';
import * as yup from 'yup';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'

import { Link } from "react-router-dom";

import ConditionalAlert from 'components/ConditionalAlert';
import MyForm, {Field, Check} from 'components/MyForm';

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/auth/actions'

// hooks
import { useRedux, useLog, useAlert, usePageTitle } from 'hooks/components';
import { getLocalStorageItem, removeLocalStorageItem } from 'hooks/storage';
import routes from 'routes';


const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
    _remember_me: yup.bool()
});

const Login = (props) => {

    const logConfig = {
        name: 'Login'
    };
    const reduxConfig = {
        selector: 'auth',
        reRender: (a, b) => a.user.id !== b.user.id // rerender only if user log in/out
    }

    useLog(logConfig)
    const [auth, dispatch] = useRedux(reduxConfig, logConfig);

    const [loginAlert, setLoginAlert] = useState();

    useEffect(() => {
        getLocalStorageItem('login_alert', (alert) => {setLoginAlert(alert)})
        removeLocalStorageItem('login_alert', "Deleted login alert from localStorage")
    }, [])

    usePageTitle(`Geotek - Login Area Riservata`);

    return (
        <div className='root'>

            <MyForm
                legend="Login"
                schema={schema}
                request = { (values, callbacks) => { dispatch(actions.authenticate(values, callbacks)); } }
                initialValues={{
                    email: '',
                    password: '',
                    _remember_me: '',
                }}
                submitButton={{title: 'Login'}}
                startAlert={loginAlert}
                onComplete={{
                    messages:{
                        error: (code, data) => ( code == 500 ? '' : 'Autenticazione Fallita' )
                    }
                }}
                md={6} lg={4}
            >
                <Field
                    id='1'
                    type="text"
                    placeholder="Email"
                    name="email"
                />
                
                <Field
                    id='2'
                    type="password"
                    placeholder="Password"
                    name="password"
                />

                <Check
                    id='3'
                    type='checkbox'
                    name="_remember_me"
                    label={`Ricordami`}
                />

            </MyForm>

            <Link to={routes.reserved.public.forgot_password}>Forgot password</Link>
        </div>
    )
}

export default Login;
