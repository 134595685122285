import React, {useEffect} from 'react'
import UIkit from 'uikit'

import MediaQuery from 'react-responsive'
import { useHistory, Link } from "react-router-dom";
import { motion } from 'framer-motion';
import {HashLink} from 'react-router-hash-link'

import {usePageTitle} from 'hooks/components'
import routes from 'routes'

import LegalFooter from 'components/pages/LegalFooter';

import logo_black from 'images/logo_black.webp'
import wallpaper from 'images/home/wallpaper.png'
import logo_white from 'images/logo_white.webp'

import affidabili from 'images/home/affidabili.png'
import esperti from 'images/home/esperti.png'
import innovativi from 'images/home/innovativi.png'
import ristrutturazione from 'images/home/ristrutturazione.png'
import interventi from 'images/home/interventi.png'
import impianti from 'images/home/impianti.png'
import realizzazione from 'images/home/realizzazione.png'


import './home.css'

const NavLinks = () =>{

    useEffect(()=>{
        const offcanvas = document.getElementById('offcanvas-menu');
        if(offcanvas != null){
            offcanvas.querySelectorAll('a').forEach( a => a.addEventListener('click', ()=>{UIkit.offcanvas(offcanvas).hide()}));
        }
    },[])

    return(
        <>
        <li><a href="#about">chi siamo</a><div className='indicator' /></li>
        <li><a href="#services">servizi</a><div className='indicator' /></li>
        <li>
            <a href="#gallery">galleria</a><div className='indicator' />
        </li>
        <li><a href="#contact">contatti</a><div className='indicator' /></li>
        </>
    )
}

const homeContainerVariants = {
    initial:{
        x: "-100vw",
        transition: { ease: 'easeInOut'}
    },
    enter: {
        x: "0",
        transition: { ease: 'easeInOut'}
    },
    exit: {
        x: "-100vw",
        transition: { ease: 'easeInOut'}
    }
}

function Home() {

    usePageTitle(`Geotek - Impianti tecnologici, Edilizia, Servizi e Forniture in opera`);

    return (
        <motion.div className=''
            variants={homeContainerVariants}
            exit='exit'
        >

            <div className='hero'>
                <div className="parallax uk-background-cover uk-flex" uk-parallax="bgx: -100" style={{ backgroundImage: `url(${wallpaper})` }}>
                    <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
                        
                        <nav className="home-nav uk-navbar-container uk-navbar-transparent" uk-navbar="" uk-parallax="background-color: #00000000, white; easing: 1.5; viewport: 0.9;">

                            <div className="uk-navbar-left">

                                <a href="" className="uk-navbar-item uk-logo" uk-parallax="opacity: 0, 1; easing: 1.5; viewport: 0.9;"><img src={logo_black} /></a>

                            </div>

                            <div className="uk-navbar-right">

                                <MediaQuery minWidth={950}>
                                    <ul className="uk-navbar-nav" 
                                        uk-scrollspy-nav="closest: li; scroll: true; offset: 84; overflow: false;"
                                        uk-parallax="color: white, black; easing: 1.5;"
                                    >
                                        <NavLinks />
                                    </ul>
                                </MediaQuery>

                                <MediaQuery maxWidth={950}>
                                    <button className="" type="button" uk-toggle="target: #offcanvas-menu"  uk-parallax="color: white, black; easing: 1.5;"><span uk-icon={`icon: menu; ratio: 1.2;`} /></button>

                                    <div id="offcanvas-menu" uk-offcanvas='overlay: true; flip: true;'>
                                        <div className="uk-offcanvas-bar">

                                            <ul className="offcanvas-nav" uk-scrollspy-nav="closest: li; scroll: true; offset: 84; overflow: false;">
                                                <NavLinks />
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </MediaQuery>

                            </div>

                        </nav>
                    </div>

                    <div className='hero-section' uk-parallax="y: 0, 200; opacity: 1, 0; easing: 0.5">
                        <div className='banner'>
                            <div className='container-sm'>
                                <div className="hero-card">
                                    <div className="hero-logo container">
                                        <img src={logo_white} />
                                    </div>
                                    <div className="hero-caption">
                                        <p>Impianti tecnologici, Edilizia, Servizi e Forniture in opera</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="hero-down" uk-parallax="y: -200; opacity: 1, 0; easing: 0.25" uk-scrollspy-nav="closest: a; scroll: true; offset: 84; overflow: false;"><a href='#about'><span uk-icon="icon: chevron-down; ratio: 1.5" /></a></div>
                    </div>
                </div>
            </div>

            <Section id='about' title='Chi Siamo'>
                <Card mediaPos='left' header='Esperti' image={esperti}
                    body='Conosciamo i prodotti nel mercato Italiano ed estero, e proponiamo le soluzioni più adatte alle esigenze dei nostri clienti, che per noi sono opportunità di costruire, con i nostri Partners, un valido supporto in fase di progettazione, installazione e manutenzione.
Col nostro impegno e la nostra professionalità anticipiamo le scelte e le strategie da attuare, facendo di Geotek una delle aziende tecnologicamente più avanzate, per mantenere i livelli di eccellenza richiesti dal mercato.
Lavoriamo dal 2009 nel territorio di Palermo e nella sua provincia, sia con clienti privati che con le amministrazioni.'/>
                <Card image={innovativi} mediaPos='left' header='Innovativi' body='Studiamo accuratamente ogni fase dei progetti a noi affidati, avvalendoci di tecnici ed architetti del design, e scegliamo i migliori prodotti in funzione delle esigenze del cliente, realizzando impianti su misura.
I nostri particolari fanno la differenza, grazie ad ottime rifiniture, anche in piccoli appartamenti dove è molto importante utilizzare ogni piccolo spazio.'/>
                <Card image={affidabili} mediaPos='left' header='Affidabili' body="Geotek si occupa di tutti i dettagli per fornire un servizio completo che soddisfi a pieno: dal sopralluogo alla progettazione, dall’iter burocratico allo smaltimento finale degli sfabbricidi in discariche autorizzate.
Offriamo progetti esecutivi molto dettagliati e ci occupiamo di tutti gli adempimenti presso le pubbliche amministrazioni ed il catasto.
Curiamo l'arredamento e l'illuminazione e grazie alla resa fotorealistica degli interni e degli esterni rappresentiamo il vostro desiderio."/>
            </Section>

            <Section id='services' title='Servizi'>
                <div className='row'>
                    <CardMediaTop image={impianti} header='Impiantistica'>
                        <ul>
                            <li>Elettricità</li>
                            <li>Idraulica</li>
                            <li>Allarmi e Video sorveglianza</li>
                            <li>Domotica</li>
                            <li>Illuminazione</li>
                            <li>Condizionamento</li>
                            <li>Riscaldamento</li>
                        </ul>
                    </CardMediaTop>
                    <CardMediaTop image={ristrutturazione} header='Ristrutturazione' >
                        <ul>
                            <li>Restauro prospetti</li>
                            <li>Ristutturazioni interne</li>
                            <li>Lavorazioni in cartongesso</li>
                            <li>Ripristino strutture intelaiate</li>
                        </ul>
                    </CardMediaTop>
                    <CardMediaTop image={realizzazione} header='Realizzazione' >
                        <ul>
                            <li>Costruzioni in cemento armato ordinario</li>
                            <li>Costruzioni in cartongesso</li>
                            <li>Coperture edili in legno</li>
                            <li>Pavimentazioni, pavimento flottante, basamento ombrario</li>
                            <li>Caminetti</li>
                            <li>Realizzazione area ad accesso controllato (tornelli)</li>
                        </ul>
                    </CardMediaTop>
                    <CardMediaTop image={interventi} header='Interventi'>
                        <ul>
                            <li>Interventi esterni</li>
                            <li>Segnaletica orizzontale per parcheggi e posti auto</li>
                            <li>Pulizia di fine cantiere</li>
                        </ul>
                    </CardMediaTop>
                </div>
                <div className='row'>
                    Ad ogni vostro contatto effettueremo un sopralluogo, garantendovi una visita tempestiva e gratuita.
                    Il servizio viene svolto da personale qualificato e specializzato e con una comprovata esperienza.
                    Una volta individuata la portata dell’intervento, prepariamo un preventivo, il più delle volte con diverse soluzioni, delle opere necessarie.
                    Dal momento in cui ci contattate, mettiamo la nostra consulenza tecnica a vostra disposizione, per rispondere ad ogni esigenza di chiarimento e valutare assieme la convenienza dell’intervento.
                </div>
            </Section>

            <Section id='gallery' title='Galleria'>
                <HashLink to={routes.public.gallery+'/#'} scroll={(el) => { 
                    window.scrollTo({ top: 0, behavior: 'instant' });  
                }}>
                    <div className='iconed-link'><span uk-icon={`icon: image; ratio: 1.2;`} /> Visualizza galleria dei lavori</div>
                </HashLink>
            </Section>

            <Section id='contact' title='Contatti'>
                <div className='margin-bottom d-flex justify-content-center'>
                    Geotek è a vostra disposizione, ed è lieta di fornire informazioni circa i servizi offerti e rispondere ai vostri quesiti.
                </div>

                <div className='row margin-bottom'>

                    <div className='col-md col-space-around'>
                        <ContactGroup ukIcon='location'>
                            <ContactEntry>Sede Legale : Viale del Fante, 56 - 90146 Palermo, Italia</ContactEntry>
                            <ContactEntry>Uffici amm.vi e Segreteria : Via A. De Gasperi, 30 - 90146 Palermo, Italia</ContactEntry>
                        </ContactGroup>
                        <ContactGroup ukIcon='social' classes='pt-4'>
                            <ContactEntry><a target='_blank' href='https://www.facebook.com/GeotekGMonti'><span uk-icon={`icon: facebook; ratio: 1.2;`} /></a></ContactEntry>
                        </ContactGroup>
                    </div>

                    <div className='col-md iframe'>

                        <iframe width="300" height="300" src="https://maps.google.com/maps?width=300&amp;height=300&amp;hl=en&amp;q=geotek%20palermo+(Geotek)&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>

                    </div>
                </div>

                <div className='row'>

                    <div className='col-md'>
                            <ContactGroup ukIcon='receiver'>
                                    <ContactEntry>Tel. <a href="tel:+39 091 5072243">+39 091 5072243</a></ContactEntry>
                                    <ContactEntry>Mobile: <a href="+39 347 9978371">+39 347 9978371</a></ContactEntry>
                                    <ContactEntry>Fax: <a href="+39 091 5086416">+39 091 5086416</a></ContactEntry>
                            </ContactGroup>
                    </div>
                    
                    <div className='col-md'>
                        <ContactGroup ukIcon='mail'>
                            <ContactEntry>Segreteria : <a href= "mailto:info@geoteksystem.it" >info@geoteksystem.it</a></ContactEntry>
                            <ContactEntry>Amm.ne : <a href= "mailto:amministrazione@geoteksystem.it">amministrazione@geoteksystem.it</a></ContactEntry>
                            <ContactEntry>PEC : <a href= "mailto:geoteksystem@pec.it">geoteksystem@pec.it</a></ContactEntry>
                        </ContactGroup>
                    </div>

                </div>
            </Section>

            <LegalFooter />
        </motion.div>
    )
}

const Section = ({ children, id, title }) => {
    return (
        <div className='section'>
            <div id={id} className=' container-sm'>

                <div className="section-title">
                    <h3>{title}</h3>
                </div>

                <div className='section-content'>
                    {children}
                </div>

            </div>
        </div>
    )
}

const Card = ({ mediaPos, header, body, image, }) => {
    return (
        <div className='section-card row'>
            <div className='card-icon col-md-4'>
                <div className='row h-100 justify-content-center align-items-center'> <img src={image} alt="" /> </div>
            </div>

            <div className='card-content col-md-8'>
                <div className=" card-title">
                    <h3 className="">{header}</h3>
                </div>
                <div className="">
                    <p>{body}</p>
                </div>
            </div>
        </div>
    )
}

const CardMediaTop = ({ header, image, children }) => {
    return (
        <div className='section-card col-lg-3 col-md-6'>
            <div className='card-icon row'>
                <div className='row h-100 justify-content-center align-items-center'> <img src={image} alt="" /> </div>
            </div>

            <div className='card-content row justify-content-center'>
                <div className="fit-content row">
                    <h3 className="fit-content">{header}</h3>
                    {children}
                </div>
            </div>
        </div>
    )
}

const ContactGroup = ({children, ukIcon, classes}) =>{
    return(
        <div className={`row contact-group ${classes}`}>
            <div className='col-2 icon'><span uk-icon={`icon: ${ukIcon}; ratio: 1.2;`} /></div>
            <div className='col'>
                <ul>
                    {children}
                </ul>
            </div>
        </div>     
    )
}

const ContactEntry = ({ children, ukIcon }) => {
    return (<li><div className='row'> {children}</div></li>)
}

export default Home
