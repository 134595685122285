// core
import React, { useState, useEffect, useRef, useContext } from 'react'
import $ from 'jquery'
import log from 'Logging'
import axios from 'axios';
import UIkit from 'uikit';

// auth
import roles from 'state/auth/roles'

// hooks
import { useLog, useRedux, useModal } from 'hooks/components'
import { useBackend } from 'hooks/ajax';
import { backend } from 'routes';

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/gallery_manager/actions'

// components
import LazyImage from 'components/LazyImage'
import { CheckBox, CheckBoxWithLabel } from 'components/pages/reserved/gallery_manager/CheckBox'
import MyForm, { Field, Check, PopoverForm, Editable, SingleButtonAction } from 'components/MyForm';
import { AddImagesForm, AddWorkForm, MoveWorkToCategoryForm } from 'components/pages/reserved/gallery_manager/EditForms'

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react"

import FocusLock from 'react-focus-lock';

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuIcon,
    MenuCommand,
    MenuDivider,
    useDisclosure
} from "@chakra-ui/react"

import { motion } from 'framer-motion';

import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from "react-bootstrap/AccordionContext";

import Modal from 'react-bootstrap/Modal'

import { Shimmer, ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { mergeStyles } from '@fluentui/react/lib/Styling';

import { GalleryContext } from 'components/pages/reserved/gallery_manager'
import RequireRole from '../RequireRole';

import working from 'images/working.png'

const AccordionContent = ({ work }) => {

    const logConfig = {
        name: `Accordion content ${work.id}`,
        color: "green"
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => (
            ((a.active.work.id == work.id) || (b.active.work.id == work.id))
            && (
                (a.active.work.selectedImages !== b.active.work.selectedImages)
                || (a.active.work.images !== b.active.work.images)
                || (a.active.work.description !== b.active.work.description)
            )
        ) // rerender only if having to do with own id when images/selectedImages/description changes 
    }, logConfig);

    const openLightBox = (image) => {
        dispatch(actions.activate.image(image));
    }

    const handleSelectImage = (event) => {

        const checked = event.target.checked;

        console.log(checked, event.target.id, (!!checked ? "checked" : "_"))

        if (!checked) {
            console.log("not all selected")
            $("#select-all-" + galleryManager.active.work.id).prop("checked", "")
        }

        const image = galleryManager.active.work.images[galleryManager.active.work.images.findIndex(img => img.id == event.target.id)];
        dispatch(actions.select.image(image, checked));
    }

    const handleSelectAll = (event) => {
        console.log("select all: " + event.target.checked)
        dispatch(actions.select.allImages(event.target.checked));
    };

    return (
        <>
            <div id={"work-" + work.id} className="uk-accordion-content">

                {galleryManager.active.work.images !== null ? // finished loading

                    <>
                        <div className="work-description">
                            <RequireRole role={roles.admin} fallback={<p>{galleryManager.active.work.description}</p>}>

                                <Editable
                                    currentValue={galleryManager.active.work.description}
                                    formConfig={{
                                        route: `${backend.reserved.manage.gallery.edit.work}/${work.id}/description`,
                                        fieldName: 'newDescription',
                                        fieldType: 'textarea',
                                        placeHolder: 'Aggiungi Descrizione',
                                    }}
                                    stateCallback={(newDescription) => { dispatch(actions.edit.work.description(work, newDescription)) }}
                                    clickThru={true}
                                    required={false}
                                />

                            </RequireRole>
                        </div>

                        {galleryManager.active.work.images.length !== 0 ?

                            <>
                                <RequireRole role={roles.admin}>
                                    <div className="images-controls align-items-center">
                                        <CheckBoxWithLabel id={"select-all-" + work.id} label={"Seleziona tutte"} onCheckboxChange={handleSelectAll} />
                                        {
                                            galleryManager.active.work.isAtLeastOneImageSelected && (<>
                                                <button className="uk-button uk-button-danger uk-button-small button-iconed" type="button"> <span uk-icon="icon: trash; ratio: 0.7" /> Elimina selezionate</button>
                                                <div className="drop-confirm" uk-drop="mode: click; pos: bottom-left; offset: 0">
                                                    <SingleButtonAction
                                                        legend="Confirm?"
                                                        backendRoute={`${backend.reserved.manage.gallery.delete.images}`}
                                                        injectedFormData={{
                                                            inputName: 'imagesIds',
                                                            getFormDataObj: () => (galleryManager.active.work.selectedImages.map(si => si.id))
                                                        }}
                                                        ukIcon='trash' ukClass='uk-button-danger'
                                                        stateCallback={() => { UIkit.drop('.drop-confirm').hide(false); dispatch(actions.delete.selectedImages()); }} />
                                                </div>
                                            </>
                                            )
                                        }
                                    </div>
                                </RequireRole>

                                <div className="uk-grid-medium uk-grid" id={"grid-" + work.id}>

                                    {galleryManager.active.work.images.map((image) => {

                                        const index = galleryManager.active.work.selectedImages.indexOf(image);
                                        const isImageSelected = index >= 0;

                                        return (
                                            <div key={image.id} /* this div is just for grid purpose */ >
                                                <div className="uk-inline">
                                                    <LazyImage key={image.id} id={image.id} src={image.thumb_url} placeholder={'loader'} onClick={() => openLightBox(image)} />
                                                    <RequireRole role={roles.admin}>
                                                        <div className="check-image-container uk-overlay uk-position-top-left">
                                                            <CheckBox
                                                                key={image.id}
                                                                id={image.id}
                                                                onCheckboxChange={handleSelectImage}
                                                                checked={isImageSelected}
                                                                style={{ visibility: isImageSelected ? 'visible' : 'hidden' }}
                                                            />
                                                        </div>
                                                    </RequireRole>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }

                                </div>
                            </>
                            : <WorkInProgress />
                        }
                    </>

                    :

                    <>
                    { galleryManager.active.work.id !== null &&
                        <Fabric className="shimmers-list">
                            <Shimmer shimmerElements={[{ type: ShimmerElementType.line, width: 100, height: 40 }]} />
                            <div style={{ marginTop: "10px" }} uk-grid="" className="uk-grid-medium" id={"grid-" + work.id}>
                                {[...Array(5).keys()].map(k =>
                                    <Shimmer key={k} shimmerElements={[
                                        { type: ShimmerElementType.line, height: 100, width: 100 },
                                    ]} />
                                )}
                            </div>
                        </Fabric>
                    }
                    </>
                }

            </div>
        </>
    )
}

const chevronDownVariants = {
    open: { rotate: 180, },
    closed: { rotate: 0 }
};

function AccordionToggle({ children, eventKey, callback }) {

    const currentEventKey = useContext(AccordionContext);

    const isCurrentEventKey = currentEventKey === eventKey;

    const accordionToggle = useAccordionToggle(
        eventKey,
        () => callback && callback(isCurrentEventKey),
    );

    return (<>
        <button
            type="button"
            className={`${isCurrentEventKey ? 'open' : 'closed'} selector uk-button uk-button-default`}
            onClick={accordionToggle}
        >
            {children(isCurrentEventKey)}
        </button>
        <div className='buttonGroup-background'></div>
        </>
    );
}

const AccordionItem = ({ work }) => {

    const logConfig = {
        name: `AccordionItem ${work.id}`,
        color: "yellow"
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => (((a.active.work.id == work.id) || (b.active.work.id == work.id)) && (a.active.work !== b.active.work)) // rerender only if having to do with own id when some property changes
    }, logConfig);

    const ref = useRef();

    const toggleActiveAccordionItem = (toggledWork, isOpen) => {

        $("#select-all-" + work.id).prop("checked", ""); // in case was left checked

        // if (!!galleryManager.active.work && galleryManager.active.work.id === toggledWork.id) { // already open -> close
        if (isOpen) {
            dispatch(actions.activate.work(null));
        }
        else { // was closed -> open
            dispatch(actions.activate.work(toggledWork, ref)); // async thunk, fetches images
        }

    }

    const [openEditable, setOpenEditable] = useState(false);

    const [uploadModal, setUploadModal] = useModal();

    return (
        <>
            <div ref={ref} className="accordion-item" id={`accordion-item-${work.id}`}>

                <div className="uk-inline">
                    <AccordionToggle eventKey={work.id} callback={(isOpen) => toggleActiveAccordionItem(work, isOpen)}>
                        {
                            isOpen => (
                                <>
                                    <motion.div
                                        animate={`${isOpen ? 'open' : 'closed'}`}
                                        variants={chevronDownVariants}
                                    >
                                        <span uk-icon="icon: chevron-down; ratio: 0.7" />
                                    </motion.div>

                                    <div className="name-editable">
                                        <RequireRole role={roles.admin} fallback={<p>{work.name}</p>}>
                                            <Editable
                                                currentValue={work.name}
                                                formConfig={{
                                                    route: `${backend.reserved.manage.gallery.rename.work}/${work.id}`,
                                                    fieldName: 'newName',
                                                    placeHolder: 'Nuovo Nome',
                                                }}
                                                stateCallback={(newName) => { dispatch(actions.edit.work.name(work, newName)) }}
                                                clickThru={false}
                                                setOpened={setOpenEditable}
                                                opened={openEditable}
                                            />
                                        </RequireRole>
                                    </div>
                                </>
                            )}
                    </AccordionToggle>

                    <RequireRole role={roles.admin}>
                        <Menu isLazy>
                            <MenuButton className="dots uk-button uk-button-default">
                                <div className="popover-span-container">
                                    <span uk-icon="icon: more-vertical; ratio: 0.7" />
                                </div>
                            </MenuButton>

                            <MenuList>
                                <MenuGroup title={work.name}>
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li onClick={() => { setUploadModal.title(`Carica immagini in: ${work.name}`); setUploadModal.key(work.id); setUploadModal.show(); }}><p>Aggiungi Immagini</p></li>
                                        <li onClick={() => { setOpenEditable(true) }}><p>Rinomina</p></li>
                                        <li className='popoverFormLi'><PopoverForm placement="left" trigger={{ className: '' }} form={MoveWorkToCategoryForm} work={work} category={galleryManager.active.category} redux={[galleryManager, dispatch]}> Sposta </PopoverForm></li>
                                        <li><p>Elimina</p></li>
                                        <div className="drop-confirm" uk-drop="mode: click; pos: left-center">
                                            <SingleButtonAction legend="Confermi?" backendRoute={`${backend.reserved.manage.gallery.delete.work}/${work.id}`} ukIcon='trash' ukClass='uk-button-danger' stateCallback={() => { dispatch(actions.delete.work(work)) }} />
                                        </div>
                                    </ul>
                                </MenuGroup>
                            </MenuList>

                        </Menu>
                    </RequireRole>


                </div>

                <Accordion.Collapse eventKey={work.id}>
                    <AccordionContent work={work} />
                </Accordion.Collapse>

            </div>

            <RequireRole role={roles.admin}>
                <UploadModal work={work} modal={uploadModal} setModal={setUploadModal} redux={[galleryManager, dispatch]} />
            </RequireRole>

        </>
    )
}

function WorksPane() {

    const logConfig = {
        name: `WorksPane`,
        color: "yellow"
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => ( (a.active.category.id !== b.active.category.id) || (a.active.category.works !== b.active.category.works) ) // rerender only if category works change
    }, logConfig)

    return (
        <div id="works-pane">

            { galleryManager.categories !== null ?
                <div className="pane-header uk-inline">
                    <div className="uk-text-muted">
                        Lavori in: <span className="text-uppercase text-accent-bold">{galleryManager.active.category.name}</span>
                    </div>
                    <RequireRole role={roles.admin}>
                        <PopoverForm placement="left" trigger={{ tooltip: { msg: "Aggiungi Lavoro", pos: 'left' }, className: 'popover-trigger' }} form={AddWorkForm} category={galleryManager.active.category} redux={[galleryManager, dispatch]} ><span uk-icon="icon: plus; ratio: 0.7" /></PopoverForm>
                    </RequireRole>
                </div>
                :
                <div className='pane-header-shimmer'>
                    <Shimmer />
                </div>
            }

            {  galleryManager.active.category.works != null ? (
                <>

                    { galleryManager.active.category.works.length != 0 ?
                        <Accordion>

                            {galleryManager.active.category.works.map((work) => {
                                if(!(galleryManager.galleryDisplayOnly == true && work.images == [])){ // if displayonly discard the empty works
                                    return (<AccordionItem key={work.id} work={work} />)
                                }
                            })
                            }
                        </Accordion>
                        : <WorkInProgress />
                    }

                </>
            ) : <>
                    <Fabric className="shimmers-list">

                        {[...Array(5).keys()].map(k =>
                            <Shimmer key={k} shimmerElements={[{ type: ShimmerElementType.line, width: 100, height: 70 }]} />
                        )}

                    </Fabric>
                </>
            }

        </div>
    )
}

const WorkInProgress = ({}) => {
    return(
        <div className='container-sm work-in-progress'>
            <div className='row justify-content-center'>
                <img src={working}/>
            </div>
            <div className='row justify-content-center'>
                <p>Lavori in corso</p>
            </div>
        </div>
    )
}

const UploadModal = ({ work, modal, setModal, redux }) => {
    return (<Modal
        show={modal.show}
        onHide={setModal.close}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        dialogClassName="modal-90w"
    >
        <Modal.Header closeButton>
            <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <AddImagesForm redux={redux} work={work} />

        </Modal.Body>
    </Modal>)
}

export default WorksPane
