import React, { useEffect , useState } from 'react'
import { Route, Redirect, useLocation, useParams } from 'react-router-dom'

// routes
import routes, { backend } from 'routes'
import GalleryManager from 'components/pages/reserved/gallery_manager';
import Login from 'components/pages/reserved/public/login';

// auth
import roles from 'state/auth/roles'

// store
import { useDispatch } from 'react-redux'
import actions from 'state/auth/actions'

// hooks
import { useBackend } from 'hooks/ajax';
import { getLocalStorageItem } from 'hooks/storage';

const AuthenticatedComponent = ({ component: Component, ...rest }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.authenticate()) // if not authenticated (async check)  set user to null (or correct if different from localstorage) and rerender app with correct permissions
    }, []);

    return (
        <Component {...rest} />
    )
}

const LoginRoute = ({ role, redirectTo, redux, ...rest }) => {
    
    const [auth, dispatch] = redux;

    useEffect(() => {
        // at load, check local storage for persisted user
        getLocalStorageItem('user', (loggedInUser) => dispatch(actions.setUser(loggedInUser)));
    }, []);
    
    return <Route exact render={() => (
        auth.user.roles.includes(role) ? <Redirect to={redirectTo} /> : <Login />
    )} {...rest} />
}

const ReservedRoute = ({ redux, role, component: Component, redirectTo, ...rest }) => {
    
    const [auth, dispatch] = redux;

    useEffect(() => {
        // at load, check local storage for persisted user
        getLocalStorageItem('user', (loggedInUser) => dispatch(actions.setUser(loggedInUser)));
    }, []);
    

    return <Route exact render={() => (
        auth.user.roles.includes(role) ? <AuthenticatedComponent component={Component} /> : <Redirect to={redirectTo} />
    )} {...rest} />
}

const TokenizedComponent = ({ verifyTokenPath, component: Component }) => {

    const logConfig = {
        name: 'TokenizedComponent'
    };

    const { token } = useParams();

    const [check, checkResponse] = useBackend({
        route: verifyTokenPath + `/${token}`,
        method: 'get',
        logTitle: {
            fetch: `Token check Response: `
        }
    }, logConfig)

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        check(null, {
            onError: (code, data) => {
                setChecked(code == 302) // if http_found -> token verified
            }
        })
    }, []);

    return (
        <>
            { checkResponse.loaded ?
                <>
                    { checked ? <Component token={token} tokenVerifiedData={checkResponse.data} />
                        : 'Invalid Token'
                    }
                </>
                : <></>
            }
        </>
    )
}

/* const TokenizedRoute = ({ verifyTokenPath, component: Component, ...rest }) => {
    return <Route exact render={() => (
        <TokenizedComponent component={Component} verifyTokenPath={verifyTokenPath} />
    )} {...rest} />
} */

export { ReservedRoute, LoginRoute, TokenizedComponent };