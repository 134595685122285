import React, {useEffect} from 'react'

// hooks
import { useLog, useRedux } from 'hooks/components'

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/gallery_manager/actions'

// auth
import roles from 'state/auth/roles'

import { HashLink } from 'react-router-hash-link';
import {Link} from 'react-router-dom'

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
} from "@chakra-ui/react"

import MediaQuery from 'react-responsive'

import { Shimmer, ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';

import RequireRole from '../RequireRole';
import CategoriesPane from 'components/pages/reserved/gallery_manager/CategoriesPane'
import UserControl from 'components/pages/reserved/UserControl'

import favicon from 'images/favicon.ico';

const Header = ({title='Galleria [Manager]'}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()  

    const logConfig = {
        name: 'Header',
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a,b) =>  (a.categories !== b.categories)
    }, logConfig);

    return (
        <nav className="uk-navbar-container" uk-navbar="" >

            <div className="uk-navbar-left">

                {/* for future addings*/}
                <ul className="uk-navbar-nav">
                    <li className='home-link'>
                        <HashLink
                            to={"/#gallery"}
                            scroll={(el) => { 
                                // el.scrollIntoView({ behavior: 'instant', block: 'start' });
                                const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
                                const yOffset = -84; 
                                window.scrollTo({ top: yCoordinate + yOffset, behavior: 'instant' });  
                            }}
                        >
                            <span uk-icon="icon: arrow-left; ratio: 1.5" className="uk-icon back-icon" />
                            <span><img src={favicon}/></span>
                        </HashLink>
                    </li>
                </ul>

            </div>

            <MediaQuery minWidth={400}>
                <div className="uk-navbar-center">
                    <p className="uk-navbar-item uk-logo">{title}</p>
                </div>
            </MediaQuery>
            
            <div className="uk-navbar-right">

                <MediaQuery maxWidth={950}>

                    <button ref={btnRef} onClick={onOpen} className="uk-button-default button-iconed" type="button"> <span uk-icon="icon: menu; ratio: 0.7" /> Raccolte </button>
                    <Drawer
                        isOpen={isOpen}
                        placement="right"
                        onClose={onClose}
                        finalFocusRef={btnRef}
                    >
                        <DrawerOverlay>
                            <DrawerContent>

                                <DrawerCloseButton />
                                <DrawerHeader>Raccolte</DrawerHeader>

                                <DrawerBody>
                                    
                                    <CategoriesPane displayHeader={false} isMobile={true}/>

                                </DrawerBody>

                            </DrawerContent>
                        </DrawerOverlay>
                    </Drawer>

                </MediaQuery>

                <RequireRole role={roles.admin}>
                    <UserControl />
                </RequireRole>
            
            </div>

        </nav>
    )
}

export default Header
