import React, { useState, useEffect } from "react";
import log from 'Logging'

const CheckBox = ({ id, onCheckboxChange, checked, style }) => {

  useEffect(() => {
    log.Rendering("Checkbox " + id + " rendered", "state: "+(!!checked ? "checked" : "_") );
    
  })

    return (<label className="lbl-checkbox">
        <input
          type="checkbox"
          className="form-check-input"

          id={id}
          onChange={onCheckboxChange}
          checked={!!checked ? "checked" : ""}
          style={style}
        />
      </label>
    )

};

const CheckBoxWithLabel = ({ id, label, onCheckboxChange }) => (
  <div className="form-check">
    <input
      className="form-check-input"
      type="checkbox"
      id={id}
      onChange={onCheckboxChange}
    />
    <label className="form-check-label" htmlFor={id}>
      {label}
    </label>
  </div>
);

export { CheckBox, CheckBoxWithLabel };