// core
import React , {useEffect, useState} from 'react'
import Log, { tiers } from 'Logging';
import axios from 'axios';

// components

import { Formik } from 'formik';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'

import { Link } from "react-router-dom";

import * as yup from 'yup';
import MyForm, {Field, Check} from 'components/MyForm';

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/auth/actions'

// hooks
import { useRedux, useLog, useAlert, usePageTitle } from 'hooks/components';
import { getLocalStorageItem, removeLocalStorageItem } from 'hooks/storage';
import { useBackend } from 'hooks/ajax';
import routes, { backend } from 'routes';

const schema = yup.object().shape({
    email: yup.string().required().email()
});

const ForgotPassword = (props) => {

    const logConfig = {
        name: 'ForgotPassword'
    };
    useLog(logConfig)

    const [request, response] = useBackend({
        route: backend.reserved.public.forgotPassword,
        method: 'post'
    })
    
    usePageTitle(`Geotek - Password Dimenticata`);

    return (
        <div className='root'>

            <MyForm
                legend="Password Dimenticata"
                schema={schema}
                request = {request}
                initialValues={{
                    email: '',
                }}
                submitButton={{title: 'Invia link di reset della Password'}}
                onComplete={{
                    redirect: 'link',
                    linkTitle: 'Login',
                    path: routes.reserved.public.login,
                    messages:{
                        success: (values, data) => `Link di recupero inviato a ${values.email}`,
                        error: `Failed to send email`,
                        includeErrorData: true
                    }
                }}
            >

                <Field
                    id='1'
                    type="text"
                    placeholder="Email"
                    name="email"
                />

            </MyForm>

        </div>
    )
}

export default ForgotPassword;
