import React, { useEffect, useState, useMemo } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';

// components
import LazyImage from 'components/LazyImage'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Formik, useFormikContext } from 'formik';

import './ImageDropZone.css'
import { number } from 'yup';

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

export default function ImageDropZoneField({ id, inputName }) {

    const formik = useFormikContext();

    const [files, setFiles] = useState([]);

    // TODO: change this according to php.ini
    const MAX_TOTAL_SIZE = 52428800 // 50MB 
    // 268435456 // 256 MB
    const MAX_FILES_NUM = 20; // 20 is default

    const [totalSize, setTotalSize] = useState(0);
    const [count, setCount] = useState(0);
    const [error, setError] = useState(null);
    const [previewsLoading, setPreviewsLoading] = useState(false);

    function thumblize(file) {
        return new Promise((resolve, reject) => {
            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = () => {
                URL.revokeObjectURL(img.src);
                // Handle the failure properly
                console.log("Cannot load image", file);
                reject();
            };
            img.onload = () => {
                URL.revokeObjectURL(img.src);
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    (blob) => {
                        // Handle the compressed image. es. upload or save in local state
                        resolve(URL.createObjectURL(blob));
                    },
                    MIME_TYPE,
                    QUALITY
                );
            };
        })
    };

    const {
        acceptedFiles, fileRejections,
        getRootProps, getInputProps,
        isDragActive, isDragAccept, isDragReject
    } = useDropzone({
        accept: 'image/*',
        // getFilesFromEvent: event => myCustomFileGetter(event),
        onDrop: acceptedFiles => { // step 2) manipulate files , step 3) save files as state

            setError(null);

            let sizes = 0;
            let num = 0;
            let error = null;

            acceptedFiles.forEach(file => {
                num += 1;
                sizes += file.size;

                if (MAX_FILES_NUM < (count + num)) {
                    error = `Possono essere caricate contemporaneamente massimo ${MAX_FILES_NUM} immagini`;
                    return;
                }
                if (MAX_TOTAL_SIZE < (totalSize + sizes)) {
                    error = `Il peso totale dell'upload deve essere inferiore a ${bytesToSize(MAX_TOTAL_SIZE)}`;
                    return;
                }
            }) // if accepted files dont pass this stage, the only change is set error

            if (error) {
                setError(error);
                return;
            }

            setTotalSize(totalSize + sizes)
            setCount(count + num);

            formik.setFieldValue(inputName, formik.values.files.concat(acceptedFiles)); // set files in formik form state

            setFiles(files.concat(acceptedFiles)); // set files before thumblize so to display the list

            const promises = acceptedFiles.map(async (file) => {
                return Object.assign(file, { preview: await thumblize(file) })
            });

            set(Promise.all(promises));

            setPreviewsLoading(true);
        }
    });

    async function set(promises) {
        const n = await promises;
        setFiles(files.concat(n));
        setPreviewsLoading(false);
    }

    const remove = file => {
        setError(null);
        setTotalSize(totalSize - file.size)
        setCount(count - 1);
        const newFiles = [...files];     // make a var for the new array
        const index = newFiles.findIndex((f) => f == file);
        newFiles.splice(index, 1);        // remove the file from the array
        setFiles(newFiles);              // update the state
        formik.setFieldValue(inputName, newFiles); // update formik value
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    // step 1 ) file rejections from accept
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path}
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const displayFiles = files.map(f => (
        <li key={f.name} className='row'>
            <div className='col-1 list-dot'>
                <div></div>
            </div>
            <div className='col-9'>
                <p>{f.name} - {bytesToSize(f.size)}</p>
            </div>
            <div className='col-2'>
                <button className="uk-button uk-button-small" type="button" onClick={() => { remove(f) }}> <span uk-icon="icon: trash; ratio: 1" /> </button>
            </div>
        </li>
    ));


    return (
        <Form.Group as={Row} controlId={`validationFormik${id}`}>
            <Row>
                <section className="drop-container">
                    <div {...getRootProps({ className: 'dropzone', style })}>
                        <Form.File
                            name={inputName}
                            {...getInputProps()}
                            isInvalid={!!formik.errors[inputName]} />
                        <p>Trascina le immagini o clicca per sfogliare</p>
                    </div>
                </section>
            </Row>

            <Row className="files-container">
                <Col>
                    <section className="info-container">
                        <aside>
                            {count > 0 &&
                                <>
                                    <Form.Group>
                                        <Form.Text className="text-muted">
                                            Images: {count}
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Text className="text-muted">
                                            Size: {bytesToSize(totalSize)}
                                        </Form.Text>
                                    </Form.Group>
                                </>
                            }

                            {files.length > 0 &&
                                <aside>
                                    <ul className="uk-list uk-list-disc uk-list-primary filesList" >{displayFiles}</ul>
                                </aside>
                            }
                        </aside>
                    </section>
                </Col>

                <Col>
                    <section className="thumb-section">

                        {previewsLoading &&
                            <>
                                <Form.Group>
                                    <Form.Text className="text-muted">
                                        Caricamento anteprime (opzionale)...
                            </Form.Text>
                                </Form.Group>
                            </>
                        }

                        <div uk-grid="" className="uk-grid-medium thumbContainer">
                            {
                                files.map((file, i) => (
                                    <div key={file.name}>
                                        <div className="uk-inline">

                                            <LazyImage id={i} src={file.preview} placeholder={'loader'} />
                                            <div className="uk-overlay uk-position-cover">
                                                <button className="uk-button uk-button-small" type="button" onClick={() => { remove(file) }}> <span uk-icon="icon: trash; ratio: 1" /> </button>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        {/*
                
                <aside style={{}}>
                    <ul>{fileRejectionItems}</ul>
                </aside>
                
                */}

                    </section>
                </Col>


            </Row>

            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                {formik.errors[inputName]}
                {error}
            </Form.Control.Feedback>

        </Form.Group>
    );
}

// step 2) manipulate files
async function myCustomFileGetter(event) {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    for (var i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);

        Object.defineProperty(file, 'myProp', {
            value: true
        });

        files.push(file);
    }

    return files;
}

const thumb = {
    display: 'inline-flex',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;
const MIME_TYPE = "image/jpeg";
const QUALITY = 0.7;

function calculateSize(img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }
    return [width, height];
}