// core
import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import log from 'Logging'

// store
import { useSelector, useDispatch } from 'react-redux'
import actions from 'state/gallery_manager/actions'

// hooks
import { useLog, useRedux } from 'hooks/components'
import { useBackend } from 'hooks/ajax';
import { backend } from 'routes';

// auth
import roles from 'state/auth/roles'

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuIcon,
    MenuCommand,
    MenuDivider,
    Portal,
} from "@chakra-ui/react"

import { AddCategoryForm, DeleteCategoryForm } from './EditForms'
import MyForm, {Field, Check, PopoverForm, Editable, SingleButtonAction} from 'components/MyForm';

import { Shimmer, ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';
import RequireRole, { RoleConditioner } from '../RequireRole'

import { motion } from 'framer-motion';

// Dropdown follower
/* 
$(function() {
    $('#categories-pane > ul').on('scroll', function() {
        
        const dropdown = $('.uk-dropdown:visible', $(this)); // select only the visible one
        
        if(dropdown.length == 0)
        {
            return;
        }
        
        const parent = $(dropdown).parent();

        const parentOffset = $(parent).offset().top; // from viewport (changes with scroll)
        const parentHeight = $(parent).height();
        // var dropDownOffset = -10; // fixed uk dropdown vertical offset
        
        const posY = parentOffset; // + parentHeight; //+ dropDownOffset; 

        $(dropdown).css({
            top: posY
        });
    });
});
*/

function CategorySelector({category, redux}){

    const [openEditable, setOpenEditable] = useState(false);

    const logConfig = {
        name: `CategorySelector ${category.id}`,
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => (
            ((a.active.category.id == category.id) || (b.active.category.id == category.id))
            && (
                (a.active.category.id !== b.active.category.id)
            )
        ) // rerender only if having to do with own id when active category changes
    }, logConfig);

    const isSelected = galleryManager.active.category.id == category.id;

    return(
        <motion.div 
            className={`uk-button-group uk-width-1-1 ${isSelected ? 'selected' : 'idle'}`}        
        >
            <div className="uk-inline">
                
                <motion.button style={{padding: openEditable ? 'unset' : null}} className="selector uk-button uk-button-default" onClick={()=> dispatch(actions.activate.category(category))}>
                    <div className="name-editable">
                        
                        <RequireRole role={roles.admin} fallback={ <p>{category.name}</p> }>
                            <Editable 
                                currentValue={category.name} 
                                formConfig={{
                                    route: `${backend.reserved.manage.gallery.rename.workCategory}/${category.id}`,
                                    fieldName: 'newName',
                                    placeHolder: 'New Name',
                                }}
                                stateCallback={(newName)=>{ dispatch(actions.edit.category.name( category, newName )) }}
                                clickThru={false}
                                setOpened={setOpenEditable}
                                opened={openEditable}
                            />
                        </RequireRole>

                    </div>
                </motion.button>
                
                <RequireRole role={roles.admin}>
                    {
                        !openEditable &&

                        <Menu placement="right" isLazy>
                            <MenuButton className="dots uk-button uk-button-default">
                                <motion.div className="popover-span-container">
                                    <span uk-icon="icon: more-vertical; ratio: 0.7" />
                                </motion.div>
                            </MenuButton>

                            <Portal>
                                <MenuList>
                                    <MenuGroup title={category.name}>
                                        <ul className="uk-nav uk-dropdown-nav">
                                            <li onClick={()=>{setOpenEditable(true)}}><p>Rinomina</p></li>
                                            <li className='popoverFormLi'><PopoverForm placement="right" trigger={{className:''}} form={DeleteCategoryForm} category={category} redux={[galleryManager,dispatch]}> Elimina </PopoverForm></li>
                                        </ul>                            
                                    </MenuGroup>
                                </MenuList>
                            </Portal>

                        </Menu>
                    }       
                </RequireRole>

                <div className='buttonGroup-background'></div>

            </div>
        </motion.div>
    )
}

const UncategorizedSelector = () =>{
    
    const logConfig = {
        name: 'UncategorizedSelector',
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a, b) => (
            ((a.active.category.id == 0) || (b.active.category.id == 0))
            && (
                (a.active.category.id !== b.active.category.id)
            )
        ) // rerender only if having to do with own id when active category changes
    }, logConfig);
    
    return(
        <motion.div 
            className={`uk-button-group uk-width-1-1 ${galleryManager.active.category.id == 0 ? 'selected' : 'idle'}`} >
            
            <motion.button className="uk-button selector uk-button-default" onClick={()=> dispatch(actions.activate.category(null))}>Nascosti</motion.button>
            <div className='buttonGroup-background'></div>
        </motion.div>
    )
}

function CategoriesPane({displayHeader=true, isMobile=false}) {

    const logConfig = {
        name: 'CategoriesPane',
        color: "acquamarine"
    };
    useLog(logConfig);

    const [galleryManager, dispatch] = useRedux({
        selector: 'galleryManager',
        reRender: (a,b) => (a.categories !== b.categories) // rerender only if categories change
    }, logConfig);

    return (
        <div id="categories-pane">
            
            <div>
                
                <RequireRole role={roles.admin}>
                    <UncategorizedSelector/>
                </RequireRole>
                
                <RoleConditioner>
                    { currentRoles => ( <>
                        {   (currentRoles.includes(roles.admin) || displayHeader) &&
                        
                            <div className="pane-header uk-inline">
                                <div className="uk-text-muted">
                                    Raccolte
                                </div>
                                <RequireRole role={roles.admin}>
                                    <PopoverForm placement={`${isMobile? 'bottom' : 'right'}`} trigger={{className:'uk-button-default popover-trigger', tooltip:{msg:"Add Category",pos:'right'}}} form={AddCategoryForm} redux={[galleryManager, dispatch]}>
                                        <span uk-icon="icon: plus; ratio: 0.7" />
                                    </PopoverForm>
                                </RequireRole>
                            </div>
                        
                        } </>
                    )}
                </RoleConditioner>
                
            </div>            

            <ul className="uk-list uk-list-collapse">
                {   galleryManager.categories !== null ?
                    galleryManager.categories.map( (category) => { 
                        // TODO: add empty category discrimination in backend
                        if(!(galleryManager.galleryDisplayOnly == true && category.empty)){ // if displayonly discard the empty categories
                            return(
                            <li> 
                                <CategorySelector key={category.id} category={category} redux={[galleryManager,dispatch]} />                            
                            </li> ); 
                        }
                    } )
                    :
                    <Fabric className="shimmers-list">
                        { [...Array(5).keys()].map( k =>
                            <Shimmer shimmerElements={ [{ type: ShimmerElementType.line, width: 100, height: 70 }] } />
                        )}
                    </Fabric>
                }
            </ul>

        </div>
    )
}

export default CategoriesPane
