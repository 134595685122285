// core
import React, { useEffect, useState, useContext, useRef } from 'react'
import { Redirect } from "react-router";
import log from 'Logging';
import axios from 'axios';
import _ from 'lodash';

// components

import * as yup from 'yup';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'

import { Link, useHistory } from "react-router-dom";
import { Formik, useFormikContext } from 'formik';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

import ConditionalAlert from 'components/ConditionalAlert'

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react"

import FocusLock from 'react-focus-lock';

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuIcon,
    MenuCommand,
    MenuDivider,
    useDisclosure
} from "@chakra-ui/react"

// hooks
import { useRedux, useLog, useAlert, useOutsideClick } from 'hooks/components';
import { useBackend } from 'hooks/ajax';
import { BottomNavigation } from '@material-ui/core';

export const Field = ({ id, name=null, type, placeholder, injectedFormData=null, ...rest }) => {

    const formik = useFormikContext();
    
    useEffect(()=>{
        if(injectedFormData != null){
            formik.setFieldValue(injectedFormData.inputName, injectedFormData.getFormDataObj());
        }        
    },[injectedFormData]);

    return (
        <Form.Group as={Row} controlId={`validationFormik${id}`}>
            
            <>{
                type == 'textarea' ? 
                <Form.Control
                    as='textarea'
                    placeholder={placeholder}
                    name={name != null ? name : injectedFormData.inputName }
                    {...rest}
                    {...formik.getFieldProps(name)}
                    isInvalid={!!formik.errors[name]}
                />
                :
                <Form.Control
                    type={type}
                    placeholder={placeholder}
                    name={name != null ? name : injectedFormData.inputName }
                    {...rest}
                    {...formik.getFieldProps(name)}
                    isInvalid={!!formik.errors[name]}
                />
            }
            </> 

            <Form.Control.Feedback type="invalid">
                {formik.errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export const Select = ({children, id, name, label, ...rest}) => {
    const formik = useFormikContext();
    return(
        <Form.Group as={Row} controlId={`validationFormik${id}`} {...rest}>
            <Form.Label>{label}</Form.Label>
            <Form.Control as="select" name={name}
            {...formik.getFieldProps(name)}
            isInvalid={!!formik.errors[name]}
            >
                {children}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                {formik.errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export const Check = ({ id, name, type, label, placeholder, ...rest }) =>{
    
    const formik = useFormikContext();
    
    return (
        <Form.Group as={Row} controlId={`validationFormik${id}`} {...rest}>
            <Form.Check
                type={type}
                placeholder={placeholder}
                name={name}
                {...formik.getFieldProps(name)}
                label={label}
            />
        </Form.Group>
    ) 
}

export const IconButton = ({children, type, ukIcon, ukClass='uk-button-default', disabled}) => {
    return(
        <button className={`uk-button uk-button-small ${ukClass}`} type={type} disabled={disabled} > { !disabled && <span uk-icon={`icon: ${ukIcon}; ratio: 0.7`} />} {children} </button>
    )
}

const SubmitButton = ({ title='', component: Btn = Button, ...rest}) => {
    const formik = useFormikContext();
    return (
        <div className="submit-container uk-inline">
            <Btn type="submit" disabled={formik.isSubmitting} {...rest}>
                {title} 
            </Btn> 
            { formik.isSubmitting && ( <div className="spinner uk-overlay uk-position-cover"> <Spinner size={SpinnerSize.small} /> </div>) }
        </div>
    )
}

const defaultOnComplete = {
    redirect: "null", // or 'forced', 'link'
    path: '/',
    messages: {
        success: '',
        error: null,
        includeErrorData: false,
    },
    linkTitle: ''
};

const defaultCallbacks = {
    onSuccess: (data) => { },
    onError: (code, data) => { }
}

/**
 *
 *   @param  children Bootstrap Form Components, or Fields from MyForm.js
 *   @param  legend Name of the form
 *   @param  onComplete on success : redirect method: null -> no redirect on success, forced -> redirect to path, link -> link to path with linkTitle
 *                      messages: msgs to the form alert, on success/error ( can be functions of values,<code>,data) 
 *                      (includeErrorData to display err code)
 *   @param  callbacks onSuccess(data) and onError(code, data) when calling request, default to void
 *   @param  request useBackend request, if using custom set to example (values, callbacks) => { dispatch(actions.authenticate(values, callbacks)); } [ callbacks refers to default form callbacks ]
 *   @param  schema Yup validation schema
 *   @param  initialValues Formik initial values
 *   @param  submitButton {title, component, ukIcon, ukClass}
 *   @param  startAlert alert to show on first rendering
 *   @param  stops bootrap stops for Col container
 *   @param  reset external controlled bool prop: set true to clear alert and show the form again
 *   @param  setReset provide external reset setter to false the reset prop on ack of its truthness 
*/
export default function MyForm({
    children,
    legend=null,
    onComplete,
    callbacks,
    request,
    schema,
    initialValues,
    submitButton,
    startAlert=null,
    reset=false,
    setReset=()=>{},
    enctype=null,
    ...stops
}) {

    const _onComplete = _.mergeWith({}, defaultOnComplete, onComplete)
    const _callbacks = _.mergeWith({}, defaultCallbacks, callbacks)

    const [alert, setAlert] = useAlert();

    useEffect( () => { 
        if(reset === true){
            setAlert(null);
        }
    }, [reset])
    
    useEffect( () => { 
        if(reset === true){
            if(alert.msg != null){ // default when setAlert(null)
                setAlert(null);
                setReset(false);
            }            
        }
    }, [alert])

    useEffect(()=>{
        setAlert(startAlert);
    },[startAlert])

    function forceRedirectCheck(){
        if(_onComplete.redirect == 'forced') { window.location.href = _onComplete.path; }
    }

    useEffect(() => {console.log("myf: ", initialValues)})

    return (
        <>

            <ConditionalAlert useAlert={alert} />

            { alert.variant != 'success' ?
                <Formik
                    validationSchema={schema}
                    validateOnBlur={false}
                    onSubmit={(values, { setSubmitting }) => {
                        setAlert(null);
                        request(values, {
                            onSuccess: (data) => {
                                setSubmitting(false)
                                _callbacks.onSuccess(data, values);
                                const msg =  typeof _onComplete.messages.success === 'function' ? _onComplete.messages.success(values, data) : _onComplete.messages.success
                                setAlert({ variant: 'success', msg: msg });
                                forceRedirectCheck();
                            },
                            onError: (code, data) => {
                                setSubmitting(false)
                                _callbacks.onError(code, data);
                                const msg =  typeof _onComplete.messages.error === 'function' ? _onComplete.messages.error(values, code, data) : _onComplete.messages.error
                                setAlert({ variant: 'danger', msg: <> {msg} { _onComplete.messages.includeErrorData && ( <> { msg !== null && (<br />) } {`Error: ${code}`} <br /> {`Data: ${JSON.stringify(data)}`} </> ) } </> });
                            }
                        })
                    }}
                    initialValues={initialValues}
                >
                    {formik => (
                        <form noValidate encType={enctype} onSubmit={formik.handleSubmit}>

                            <Container>
                                <fieldset className="uk-fieldset">

                                    <Row className="justify-content-md-center">
                                        <Col {...stops} >

                                            {legend && <legend className="uk-legend">{legend}</legend>}

                                            {children}

                                            <Form.Group as={Row}>
                                                <SubmitButton {...submitButton} />
                                            </Form.Group>

                                        </Col>
                                    </Row>

                                </fieldset>
                            </Container>

                        </form >
                    )}
                </Formik >
                : 
                    _onComplete.redirect == 'link' ? <Link to={_onComplete.path}>{_onComplete.linkTitle}</Link>
                    : <></>
            }
        </>
    )
}

const defaultTrigger = {
    className: 'uk-button-default',
    tooltip: {
        msg: null,
        pos: 'left',
    }
}

export const PopoverForm = ({children, placement, trigger, form: Form, ...rest}) => {

    const { onOpen, onClose, isOpen } = useDisclosure()
    const [ formReset, setFormReset ] = useState(false);

    function open(){
        setFormReset(false);
        onOpen();
    }
    function close(){
        setFormReset(true);
        onClose();        
    }

    const _trigger = _.mergeWith({}, defaultTrigger, trigger)

    const tooltip = _trigger.tooltip.msg ? `title: ${_trigger.tooltip.msg}; pos: ${_trigger.tooltip.pos}` : null;

    return (
        <>
            <Popover
                isOpen={isOpen}
                onOpen={open}
                onClose={close}
                placement={placement}
                closeOnBlur={true}
            >
                <PopoverTrigger>
                    <button className={`${_trigger.className}`} type="button" uk-tooltip={tooltip} > {children} </button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <FocusLock returnFocus persistentFocus={false}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <Form reset={formReset} close={close} {...rest}/>
                    </FocusLock>
                </PopoverContent>
            </Popover>
        </>
    )
}

const defaultFormConfig = {
    fieldType: 'text',
    maxLength: 255,
}

/**
 * Instead of reloading entries from server, edit the state object on success with stateCallback(newValue);
 * 
 * @param formConfig :{
 *      route: '',
 *      fieldName: '',
 *      placeHolder: '',
 *      fieldType: 'text',
 *      maxLength: 255
 *  }
 * 
 * @param clickThru set to false to provide external triggering with opened
 * @param opened external useState toggle
 * @param setOpened external useState setter
 */
export const Editable = ({clickThru=true, currentValue, formConfig, stateCallback, required=true, opened=false, setOpened=()=>{} }) =>{

    const _formConfig = _.mergeWith({}, defaultFormConfig, formConfig)

    const clickRef = useRef(null);

    const [ openEditable, setOpenEditable ] = useState(false);
    const [ formReset, setFormReset ] = useState(false);

    function open(){
            setOpenEditable(true);
            setFormReset(false);
            document.addEventListener("mousedown", handleClickOutside);
    }
    function close(){
        setOpenEditable(false);
        setFormReset(true);
        document.removeEventListener("mousedown", handleClickOutside);
        setOpened(false);
    }

    useEffect(()=>{
        if(opened){
            open();
        }
    },[opened])

    function handleClickOutside(event) {
            if(!!clickRef){
                if (clickRef.current && !clickRef.current.contains(event.target) ){
                    close();
                }
            }
    }
    
    const [editRequest, editResponse] = useBackend({
        route: _formConfig.route,
        method: 'post'
    })

    const yupObj = yup.string().max(_formConfig.maxLength);
    const validate = required ? yupObj.required("required") : yupObj;

    const schema = yup.object().shape({
        [_formConfig.fieldName]: validate,
    });

    return(
        <div className='editable'>
            { !openEditable ? 
                <p onClick={(e)=>{ if(clickThru){ e.stopPropagation(); open(); }}}>{ !(currentValue == null || currentValue == "") ? currentValue : _formConfig.placeHolder }</p>
                :
                <div className="editableForm" ref={clickRef}  onClick={(e)=>{ e.stopPropagation();}} >

                    <MyForm
                        schema={schema}
                        request={editRequest}
                        initialValues={{
                            [_formConfig.fieldName]: currentValue,
                        }}
                        submitButton={{ component: IconButton, ukIcon: 'check' }}
                        onComplete={{
                            messages: {
                                success: 'Renamed',
                                includeErrorData: true
                            }
                        }}
                        callbacks={{
                            onSuccess:(data, values)=>{
                                stateCallback(values[_formConfig.fieldName]);
                                close();
                            }
                        }}
                        reset={formReset}
                    >

                        <Field
                            id='0'
                            type={_formConfig.fieldType}
                            placeholder={_formConfig.placeHolder}
                            name={_formConfig.fieldName}
                        />

                    </MyForm>

                </div>
            }
        </div>
    )
}

const defaultInjectedFormData = {
    inputName: 'dummy',
    getFormDataObj: () => ({})
}

/**
 * @param injectedFormData { inputName, getFormDataObj: () => ({}) }
 */
export const SingleButtonAction = ({legend=null, stateCallback, injectedFormData=null, backendRoute, ukIcon, ukClass=null, resetOnSuccess=true, }) => {
    
    const [request, response] = useBackend({
        route: backendRoute,
        method: 'post'
    })

    const _injectedFormData = _.mergeWith({}, defaultInjectedFormData, injectedFormData);

    const [reset, setReset] = useState(false);

    return(
        <>
            <div className="singleButtonAction" >

                <MyForm
                    legend={legend}
                    request={request}
                    submitButton={{ component: IconButton, ukIcon: ukIcon, ukClass: ukClass }}
                    onComplete={{
                        messages: {
                            includeErrorData: true
                        }
                    }}
                    initialValues={{
                        [_injectedFormData.inputName]: "",
                    }}
                    callbacks={{
                        onSuccess:(data, values)=>{
                            stateCallback();
                            if(resetOnSuccess){ setReset(true) }
                        }
                    }}
                    reset={reset}
                    setReset={setReset}
                >

                    <Field
                        id='0'
                        type="hidden"
                        injectedFormData={_injectedFormData}
                    />

                </MyForm>

            </div>
        </>
    )
}