import * as types from './actionsTypes'
import update from 'immutability-helper';
import { setLocalStorageItem, removeLocalStorageItem } from 'hooks/storage';

const anonymous = {
    id: null,
    roles: [],
    firstName: null,
    email: null,
}

const defaultAuthState = {
    user: anonymous,
    login: {}
}

const authReducer = (state = defaultAuthState, action) => {
        
    switch(action.type)
    {
        case types.SET_USER:
        {
            const user = action.payload.user;

            const newState = update( state, {
                user: {$set: user ? user : anonymous}
            })

            if(user){
                setLocalStorageItem('user', {
                    id: user.id,
                    roles: user.roles
                })
            }
            else{
                removeLocalStorageItem('user', "Deleted logged out user from localStorage")
            }
            
            return newState;
        }

        default:
            return state;
    }
}

export default authReducer;